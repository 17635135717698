import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { Button, Form, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { startAuctionFormHandler } from "../scatter/scatterActions";

class AuctionAccountForm extends Component {
  render() {
    const { handleSubmit, submitting, t } = this.props; // handleSubmit is provided by reduxForm
    return (
      <Form onSubmit={handleSubmit(startAuctionFormHandler)}>
        <div className="modalFormFieldDiv">
          <label htmlFor="auctionAcctName">
            {t("marketplace.auction.modal.auctionAcctName")}
          </label>
          <Field
            component="input"
            type="text"
            name="auctionAcctName"
            placeholder={t("marketplace.auction.modal.auctionAcctName")}
            autoComplete="off"
          />
        </div>
        <div className="modalFormFieldDiv">
          <label htmlFor="paymentAcct">
            {t("marketplace.auction.modal.paymentacct")}
          </label>
          <Field
            component="input"
            type="text"
            name="paymentAcct"
            placeholder={t("marketplace.auction.modal.paymentacct")}
            autoComplete="off"
          />
        </div>
        <div className="modalFormFieldDiv">
          <label htmlFor="buyNowPrice">
            {t("marketplace.auction.modal.buyItNowPrice")}
          </label>
          <Field
            component="input"
            type="text"
            name="buyNowPrice"
            placeholder={t("marketplace.auction.modal.buyItNowPrice")}
            autoComplete="off"
          />
        </div>
        <div className="modalFormFieldDiv">
          <label htmlFor="reservePrice">
            {t("marketplace.auction.modal.reservePrice")}
          </label>
          <Field
            component="input"
            type="text"
            name="reservePrice"
            placeholder={t("marketplace.auction.modal.reservePrice")}
            autoComplete="off"
          />
        </div>
        <Divider hidden />
        {submitting && <div>{t("messages.waitingforscatter")}</div>}
        <Button
          className="sell-account-submit-button"
          floated="right"
          type="submit"
        >
          {t("marketplace.auction.modal.submit")}
        </Button>
        <Divider hidden />
      </Form>
    );
  }
}

export default withRouter(
  connect()(
    reduxForm({
      form: "AuctionAccountForm"
    })(withTranslation()(AuctionAccountForm))
  )
);
