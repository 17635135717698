import { fromJS } from "immutable";
import * as userActions from "./userActions";

const defaultState = fromJS({
  loggedIn: false,
  loggingIn: false,
  loginError: undefined,
  registerError: undefined,

  showProfileUpdateModal: false,
  userRole: null,
  user: null,
  passwordResetError: undefined,
  passwordResetting: false,
  passwordResetMessage: undefined,
  userProfile: null,
  role: null,
  token: null,
  username: null,
  referrer: undefined
});

export default function reducer(state = defaultState, action) {
  const { payload } = action;

  switch (action.type) {
    case userActions.loginRoutine.TRIGGER: {
      return state.merge({
        loginError: undefined,
        loggedIn: false,
        user: null,
        role: null,
        token: null,
        username: null,
        loggingIn: true
      });
    }

    case userActions.loginRoutine.FAILURE: {
      const newState = state.set("loginError", payload);
      return newState;
    }

    case userActions.loginRoutine.SUCCESS: {
      localStorage.setItem("user", JSON.stringify(payload));
      return state.merge({
        loggedIn: true,
        loggingIn: false,
        user: payload,
        role: payload.role,
        token: payload.token,
        username: payload.username,
        avatar: payload.avatar
      });
    }

    case userActions.logoutRoutine.TRIGGER: {
      localStorage.removeItem("user");
      return state.merge({
        loggedIn: false,
        loggingIn: false,
        user: null
      });
    }

    case userActions.registerRoutine.FAILURE: {
      return state.set("registerError", payload);
    }

    case userActions.registerRoutine.SUCCESS: {
      return state.merge({
        registerError: undefined,
        registerStep2: true
      });
    }

    case userActions.requestProfileRoutine.FAILURE: {
      return state.set("userProfile", null);
    }

    case userActions.requestProfileRoutine.SUCCESS: {
      return state.set("userProfile", fromJS(payload));
    }

    case userActions.editProfileRoutine.FAILURE: {
      return state.set("userProfile", null);
    }

    case userActions.editProfileRoutine.SUCCESS: {
      return state.set("userProfile", fromJS(payload));
    }

    case userActions.getUserListRoutine.FAILURE: {
      return state.set("users", null);
    }

    case userActions.getUserListRoutine.SUCCESS: {
      return state.set("users", fromJS(payload));
    }

    case userActions.updateAvatarRoutine.SUCCESS: {
      return state.set("avatar", payload);
    }

    case userActions.setReferrerRoutine.TRIGGER: {
      return state.set("referrer", payload);
    }

    default:
      return state;
  }
}
