/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Segment } from "semantic-ui-react";
import LoginPage from "./Login";

class RegistrationSuccessful extends Component {
  render() {
    return (
      <div className="signInOrUp">
        <Segment padded>
          Registration Successful. You will here from us soon.
        </Segment>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => {
      return {};
    },
    dispatch => {
      return {};
    }
  )(RegistrationSuccessful)
);
