import React, { Component } from "react";

export const Provider = props => (
  <div className="login-button-container">
    <button
      id={props.id}
      className="login-button"
      type="button"
      onClick={() => props.onClick(props.id)}
    >
      <span>{props.label}</span>
      <img src="" />
      {/* <ScatterLogo /> */}
    </button>
  </div>
);
