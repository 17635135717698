import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { setPageTitleRoutine } from "../common/commonActions";

class Privacy extends Component {
  componentWillMount() {
    const { setPageTitle } = this.props;
    setPageTitle("Privacy | EOS Name Service | Create an EOS account now!");
  }

  render() {
    const { t } = this.props;
    return (
      <div className="page-contents">
        <header>
          <h1 className="_15523ab6">Privacy Policy</h1>
          <p className="_dd3480bc">
            How we gather, use, disclose and manage your data.
          </p>
        </header>
        <p>
          Protecting your private information is our priority. This Statement of
          Privacy applies to http://eosnameservice.io and EOS Name Service LLC
          and governs data collection and usage. For the purposes of this
          Privacy Policy, unless otherwise noted, all references to EOS Name
          Service LLC include http://eosnameservice.io and ENS. The ENS website
          is a ecommerce and digital goods site. By using the ENS website, you
          consent to the data practices described in this statement.
        </p>
        <h2 id="collection-of-your-personal-information">
          Collection of your Personal Information
        </h2>
        <p>
          In order to better provide you with products and services offered on
          our Site, ENS may collect personally identifiable information, such as
          your:
        </p>
        <ul>
          <li>First and Last Name</li>
          <li>E-mail Address</li>
        </ul>
        <p>
          If you purchase ENS&apos;s products and services, we collect billing
          and credit card information. This information is used to complete the
          purchase transaction.
        </p>
        <p>
          ENS may also collect anonymous demographic information, which is not
          unique to you, such as your:
        </p>
        <ul>
          <li>Age</li>
          <li>Gender</li>
          <li>Race</li>
          <li>Household Income</li>
        </ul>
        <p>
          We do not collect any personal information about you unless you
          voluntarily provide it to us. However, you may be required to provide
          certain personal information to us when you elect to use certain
          products or services available on the Site. These may include: (a)
          registering for an account on our Site; (b) entering a sweepstakes or
          contest sponsored by us or one of our partners; (c) signing up for
          special offers from selected third parties; (d) sending us an email
          message; (e) submitting your credit card or other payment information
          when ordering and purchasing products and services on our Site. To
          wit, we will use your information for, but not limited to,
          communicating with you in relation to services and/or products you
          have requested from us. We also may gather additional personal or
          non-personal information in the future.
        </p>
        <h2 id="use-of-your-personal-information">
          Use of your Personal Information
        </h2>
        <p>
          ENS collects and uses your personal information to operate its
          website(s) and deliver the services you have requested.
        </p>
        <p>
          ENS may also use your personally identifiable information to inform
          you of other products or services available from ENS and its
          affiliates.
        </p>
        <h2 id="sharing-information-with-third-parties">
          Sharing Information with Third Parties
        </h2>
        <p>
          ENS does not sell, rent or lease its customer lists to third parties.
        </p>
        <p>
          ENS may, from time to time, contact you on behalf of external business
          partners about a particular offering that may be of interest to you.
          In those cases, your unique personally identifiable information
          (e-mail, name, address, telephone number) is transferred to the third
          party. ENS may share data with trusted partners to help perform
          statistical analysis, send you email or postal mail, provide customer
          support, or arrange for deliveries. All such third parties are
          prohibited from using your personal information except to provide
          these services to ENS, and they are required to maintain the
          confidentiality of your information.
        </p>
        <p>
          ENS may disclose your personal information, without notice, if
          required to do so by law or in the good faith belief that such action
          is necessary to: (a) conform to the edicts of the law or comply with
          legal process served on ENS or the site; (b) protect and defend the
          rights or property of ENS; and/or (c) act under exigent circumstances
          to protect the personal safety of users of ENS, or the public.
        </p>
        <h2 id="tracking-user-behavior">Tracking User Behavior</h2>
        <p>
          ENS may keep track of the websites and pages our users visit within
          ENS, in order to determine what ENS services are the most popular.
          This data is used to deliver customized content and advertising within
          ENS to customers whose behavior indicates that they are interested in
          a particular subject area.
        </p>
        <h2 id="automatically-collected-information">
          Automatically Collected Information
        </h2>
        <p>
          Information about your computer hardware and software may be
          automatically collected by ENS. This information can include: your IP
          address, browser type, domain names, access times and referring
          website addresses. This information is used for the operation of the
          service, to maintain quality of the service, and to provide general
          statistics regarding use of the ENS website.
        </p>
        <h2 id="links">Links</h2>
        <p>
          This website contains links to other sites. Please be aware that we
          are not responsible for the content or privacy practices of such other
          sites. We encourage our users to be aware when they leave our site and
          to read the privacy statements of any other site that collects
          personally identifiable information.
        </p>
        <h2 id="security-of-your-personal-information">
          Security of your Personal Information
        </h2>
        <p>
          ENS secures your personal information from unauthorized access, use,
          or disclosure. ENS uses the following methods for this purpose:
        </p>
        <ul>
          <li>SSL Protocol</li>
        </ul>
        <p>
          When personal information (such as a credit card number) is
          transmitted to other websites, it is protected through the use of
          encryption, such as the Secure Sockets Layer (SSL) protocol.
        </p>
        <p>
          We strive to take appropriate security measures to protect against
          unauthorized access to or alteration of your personal information.
          Unfortunately, no data transmission over the Internet or any wireless
          network can be guaranteed to be 100% secure. As a result, while we
          strive to protect your personal information, you acknowledge that: (a)
          there are security and privacy limitations inherent to the Internet
          which are beyond our control; and (b) security, integrity, and privacy
          of any and all information and data exchanged between you and us
          through this Site cannot be guaranteed.
        </p>
        <h2 id="children-under-thirteen">Children Under Thirteen</h2>
        <p>
          ENS does not knowingly collect personally identifiable information
          from children under the age of thirteen. If you are under the age of
          thirteen, you must ask your parent or guardian for permission to use
          this website.
        </p>
        <h2 id="disconnecting-your-ens-account-from-third-party-websites">
          Disconnecting your ENS Account from Third Party Websites
        </h2>
        <p>
          You will be able to connect your ENS account to third party accounts.
          BY CONNECTING YOUR ENS ACCOUNT TO YOUR THIRD PARTY ACCOUNT, YOU
          ACKNOWLEDGE AND AGREE THAT YOU ARE CONSENTING TO THE CONTINUOUS
          RELEASE OF INFORMATION ABOUT YOU TO OTHERS (IN ACCORDANCE WITH YOUR
          PRIVACY SETTINGS ON THOSE THIRD PARTY SITES). IF YOU DO NOT WANT
          INFORMATION ABOUT YOU, INCLUDING PERSONALLY IDENTIFYING INFORMATION,
          TO BE SHARED IN THIS MANNER, DO NOT USE THIS FEATURE. You may
          disconnect your account from a third party account at any time. Users
          may learn how to disconnect their accounts from third-party websites
          by visiting their &quot;My Account&quot; page. Users may also contact
          us via email.
        </p>
        <h2 id="opt-out-&amp;-unsubscribe-from-third-party-communications">
          Opt-Out &amp; Unsubscribe from Third Party Communications
        </h2>
        <p>
          We respect your privacy and give you an opportunity to opt-out of
          receiving announcements of certain information. Users may opt-out of
          receiving any or all communications from third-party partners of ENS
          by contacting us here:
        </p>
        <ul>
          <li>Email: support@eosnameservice.io</li>
        </ul>
        <h2 id="e-mail-communications">E-mail Communications</h2>
        <p>
          From time to time, ENS may contact you via email for the purpose of
          providing announcements, promotional offers, alerts, confirmations,
          surveys, and/or other general communication. In order to improve our
          Services, we may receive a notification when you open an email from
          ENS or click on a link therein.
        </p>
        <p>
          If you would like to stop receiving marketing or promotional
          communications via email from ENS, you may opt out of such
          communications by Customers may unsubscribe from emails by
          &quot;clicking on the UNSUBSCRIBE button.&quot;
        </p>
        <h2 id="external-data-storage-sites">External Data Storage Sites</h2>
        <p>
          We may store your data on servers provided by third party hosting
          vendors with whom we have contracted.
        </p>
        <h2 id="changes-to-this-statement">Changes to this Statement</h2>
        <p>
          ENS reserves the right to change this Privacy Policy from time to
          time. We will notify you about significant changes in the way we treat
          personal information by sending a notice to the primary email address
          specified in your account, by placing a prominent notice on our site,
          and/or by updating any privacy information on this page. Your
          continued use of the Site and/or Services available through this Site
          after such modifications will constitute your: (a) acknowledgment of
          the modified Privacy Policy; and (b) agreement to abide and be bound
          by that Policy.
        </p>
        <h2 id="contact-information">Contact Information</h2>
        <p>
          ENS welcomes your questions or comments regarding this Statement of
          Privacy. If you believe that ENS has not adhered to this Statement,
          please contact ENS at:
        </p>
        <p>EOS Name Service LLC</p>
        <p>
          Email Address:
          <br />
          support@eosnameservice.io
        </p>
        <p>Effective as of January 01, 2020</p>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      return {};
    },
    dispatch => {
      return {
        setPageTitle: args => {
          dispatch(setPageTitleRoutine(args));
        }
      };
    }
  )(withTranslation()(Privacy))
);
