/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import React, { Component } from "react";
import {
  Responsive,
  TransitionablePortal,
  Segment,
  Grid
} from "semantic-ui-react";
import { withRouter, Link } from "react-router-dom";
import queryString from "query-string";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import * as scatterActions from "../scatter/scatterActions";
import Header from "../header/Header";
import Footer from "../footer/Footer";

class DesktopContainer extends Component {
  state = { open: false };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  //handleClick = () => this.setState(prevState => ({ open: !prevState.open }));

  render() {
    const { children, t, widget } = this.props;

    return (
      <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        <div className="desktop-main">
          <Header />
          <div className="children-wrapper">{children}</div>
          <Footer />
          {widget !== "true" && (
            <div
              className="desktop-sub-header"
              onClick={() => {
                const { open } = this.state;
                if (open) return;
                this.setState(prevState => ({
                  open: !prevState.open
                }));
              }}
            >
              {t("helpSlider.open")}
              <TransitionablePortal
                // eslint-disable-next-line react/destructuring-assignment
                open={this.state.open}
                transition={{
                  animation: "slide up",
                  duration: 500
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "440px",
                    position: "fixed",
                    left: "0px",
                    bottom: "0px",
                    zIndex: 1000,
                    backgroundColor: "white"
                  }}
                >
                  <div
                    className="ask-questions-open"
                    onClick={() => this.setState({ open: false })}
                  >
                    {t("helpSlider.close")}
                  </div>
                  <div className="questions-container">
                    <div className="questions-column">
                      <p className="headline">
                        <Trans i18nKey="helpSlider.infoTitle1">
                          Own your EOS account forever.
                          <br />
                          Use your existing EOS keys.
                        </Trans>
                      </p>
                      <p className="info">
                        <Trans i18nKey="helpSlider.infoContent1">
                          The ENS Marketplace allows you to sell the names you
                          already own. Simply log in with the wallet that
                          controls the names you want to sell. List the name and
                          a price for sale and it will appear on the marketplace
                          page. Then sit back, and wait for buyers who are
                          hunting for new names every day!
                        </Trans>
                      </p>
                    </div>
                    <div className="questions-column questions-middle-column">
                      <p className="headline">
                        <Trans i18nKey="helpSlider.infoTitle2">
                          What is the EOS Name Service
                          <br />
                          and how does it work?
                        </Trans>
                      </p>
                      <p className="info">
                        <Trans i18nKey="helpSlider.infoContent2">
                          Need Trans{" "}
                          <a href="https://t.me/eosnameservice">
                            needs translation
                          </a>{" "}
                          needs translation
                        </Trans>
                      </p>
                    </div>
                    <div className="questions-column">
                      <p className="headline">
                        <Trans i18nKey="helpSlider.infoTitle3">
                          Own your EOS account forever.
                          <br />
                          Use your existing EOS keys.
                        </Trans>
                      </p>
                      <p className="info">
                        <Trans i18nKey="helpSlider.infoContent3">
                          The ENS Marketplace allows you to sell the names you
                          already own. Simply log in with the wallet that
                          controls the names you want to sell. List the name and
                          a price for sale and it will appear on the marketplace
                          page. Then sit back, and wait for buyers who are
                          hunting for new names every day!
                        </Trans>
                      </p>
                    </div>
                  </div>
                </div>
              </TransitionablePortal>
            </div>
          )}
        </div>
      </Responsive>
    );
  }
}

export default withRouter(
  connect((state, ownProps) => {
    const q = queryString.parse(ownProps.location.search);
    const { refid, widget } = q;
    return {
      refid: refid ? refid : "none",
      widget
    };
  })(withTranslation()(DesktopContainer))
);
