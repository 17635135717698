import { createRoutine, bindRoutineToReduxForm } from "redux-saga-routines";

export const loginRoutine = createRoutine("user/LOGIN_USER");
export const loginFormHandler = bindRoutineToReduxForm(loginRoutine);

export const logoutRoutine = createRoutine("user/LOGOUT_USER");

export const passwordResetRoutine = createRoutine("user/RESET_PASSWORD");
export const passwordResetFormHandler = bindRoutineToReduxForm(
  passwordResetRoutine
);

export const registerRoutine = createRoutine("user/REGISTER_USER");
export const registerFormHandler = bindRoutineToReduxForm(registerRoutine);

export const updatePasswordRoutine = createRoutine("user/UPDATE_PASSWORD");
export const updatePasswordFormHandler = bindRoutineToReduxForm(
  updatePasswordRoutine
);

export const editProfileRoutine = createRoutine("user/EDIT_PROFILE");
export const editProfileFormHandler = bindRoutineToReduxForm(
  editProfileRoutine
);

export const requestProfileRoutine = createRoutine("user/REQUEST_PROFILE");

export const updateAvatarRoutine = createRoutine("user/UPDATE_AVATAR");

export const getUserListRoutine = createRoutine("user/GET_USER_LIST");

export const approveUserRoutine = createRoutine("user/APPROVE_USER");

export const suspendUserRoutine = createRoutine("user/SUSPEND_USER");

export const setReferrerRoutine = createRoutine("user/SET_REFERRER");
