import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { Button, Form, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { buyAccountFormHandler } from "../scatter/scatterActions";

class BuyAccountForm extends Component {
  render() {
    const { handleSubmit, t } = this.props; // handleSubmit is provided by reduxForm
    return (
      <Form onSubmit={handleSubmit(buyAccountFormHandler)}>
        <div className="modalFormFieldDiv">
          <label htmlFor="buyAcctName">
            {t("marketplace.buy.modal.buyacctname")}
          </label>
          <Field
            component="input"
            type="text"
            disabled={true}
            name="buyAcctName"
            placeholder={t("marketplace.buy.modal.buyacctname")}
            autoComplete="off"
          />
        </div>
        <div className="modalFormFieldDiv">
          <label htmlFor="amount">{t("marketplace.amount")}</label>
          <Field
            component="input"
            prefix="EOS"
            type="text"
            name="amount"
            disabled={true}
            placeholder={t("marketplace.amount")}
            autoComplete="off"
          />
        </div>
        <div className="modalFormFieldDiv">
          <label htmlFor="key">{t("marketplace.buy.modal.newkey")}</label>
          <Field
            component="input"
            type="text"
            name="key"
            placeholder="EOS Public Key"
            autoComplete="off"
          />
        </div>
        <Divider hidden />
        <Button
          className="buy-account-submit-button"
          floated="right"
          type="submit"
        >
          {t("marketplace.buy.modal.submit")}
        </Button>
        <Divider hidden />
      </Form>
    );
  }
}

export default withRouter(
  connect()(
    reduxForm({
      form: "BuyAccountForm"
    })(withTranslation()(BuyAccountForm))
  )
);
