import { fromJS } from "immutable";
import * as pricingActions from "./pricingActions";

const defaultState = fromJS({
  prices: null,
  rate: null
});

export default function reducer(state = defaultState, action) {
  const { payload } = action;
  switch (action.type) {
    case pricingActions.fetchPricesRoutine.SUCCESS: {
      return state.merge({
        ...payload
      });
    }
    default:
      return state;
  }
}
