import { put, takeLatest } from "redux-saga/effects";
import * as adminActions from "./adminActions";
import * as adminClientApi from "./adminClientApi";

export const adminWatches = [fetchSalesDataWatch];

export function* fetchSalesDataWatch() {
  yield takeLatest(adminActions.fetchSalesDataRoutine.TRIGGER, fetchSalesData);
}

function* fetchSalesData({ payload }) {
  const response = yield adminClientApi.getSalesData(payload.values);
  yield put(adminActions.fetchSalesDataRoutine.success(response));
}
