import React, { Component } from "react";
import { Modal, Divider, Button, Input } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import * as modalActions from "../modalActions";

class WalletDnsModal extends Component {
  copyTextToClipboard = text => {
    if (!navigator.clipboard) {
      return;
    }
    navigator.clipboard.writeText(text).then(
      () => {},
      err => {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  render() {
    const { showWalletDnsModal, closeWalletDnsModal, t } = this.props;

    return (
      <Modal
        size="tiny"
        open={showWalletDnsModal}
        onClose={closeWalletDnsModal}
      >
        <Modal.Header>Welcome to the EOS Ecosystem</Modal.Header>
        <Modal.Content>
          <div className="referral-modal-content">
            <p>{t("modals.welcomeDns.importPrivateKey")}</p>
            <ul>
              <li>
                <a href="https://get-scatter.com/" style={{ color: "#ff4536" }}>
                  Scatter
                </a>{" "}
                (desktop)
              </li>
              <li>
                <a
                  href="https://github.com/greymass/eos-voter"
                  style={{ color: "#ff4536" }}
                >
                  Greymass
                </a>{" "}
                (desktop)
              </li>
              <li>
                <a
                  href="https://www.tokenpocket.pro"
                  style={{ color: "#ff4536" }}
                >
                  TokenPocket
                </a>{" "}
                (mobile)
              </li>
              <li>
                <a
                  href="https://meet.one/download"
                  style={{ color: "#ff4536" }}
                >
                  MEET.ONE
                </a>{" "}
                (mobile)
              </li>
            </ul>
            <p>
              Then search for dapps at{" "}
              <a style={{ color: "#ff4536" }} href="https://www.eosgo.io/">
                EOS GO
              </a>{" "}
              to find out what you can do with your account!
            </p>
            <p>
              In addition to the wallets we list here, you can also use it as a
              decentralized web domain. Visit{" "}
              <a style={{ color: "#ff4536" }} href="http://www.eosdns.io">
                http://www.eosdns.io
              </a>{" "}
              to set up your domain name now.
            </p>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      return {
        showWalletDnsModal: state.modal.get("showWalletDnsModal")
      };
    },
    dispatch => {
      return {
        closeWalletDnsModal: args => {
          dispatch(modalActions.closeWalletDnsModalRoutine());
        }
      };
    }
  )(withTranslation()(WalletDnsModal))
);
