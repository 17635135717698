import { createDfuseClient, InboundMessageType } from "@dfuse/client";
import { put } from "redux-saga/effects";
import ApolloClient, { gql } from "apollo-boost";
import axios from "axios";
import * as dfuseActions from "./dfuseActions";

const gqlClient = new ApolloClient({
  uri: "https://mainnet.eos.dfuse.io/graphql"
});

const client = createDfuseClient({
  apiKey: "web_592f1711ce739d42157af4a174b865ff",
  network: "mainnet"
});

export function* getMarketPlaceNames() {
  const accountsResp = yield client.stateTable(
    "market.xyz",
    "market.xyz",
    "accounts"
  );
  const auctionsResp = yield client.stateTable(
    "market.xyz",
    "market.xyz",
    "auctions"
  );
  return { accountsResp, auctionsResp };
}

export function* getPartners() {
  const chainResponse = yield client.stateTable(
    "ens.xyz",
    "ens.xyz",
    "partners"
  );
  const ensResponse = yield axios.get("/api/admin/partners");

  const mergedResponse = ensResponse.data.map(partner => {
    const retPartner = partner;
    const chainPartner = chainResponse.rows.find(
      val => retPartner.username === val.key
    );
    if (chainPartner) {
      retPartner.percent = chainPartner.json.percent;
    } else {
      retPartner.percent = 0;
    }

    return retPartner;
  });

  return mergedResponse;
}

export function* getSalesData() {
  const ensResponse = yield axios.get("/api/admin/sales");
  return ensResponse;
}

//{ json }
