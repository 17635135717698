import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { setPageTitleRoutine } from "../common/commonActions";
import { overrideAutoPlay } from "../common/Helpers";

class About extends Component {
  componentWillMount() {
    const { setPageTitle } = this.props;
    setPageTitle("About Us | EOS Name Service | Create an EOS account now!");
  }

  render() {
    const { t } = this.props;
    return (
      <div className="page-contents">
        <header>
          <h1 className="title">{t("about.title")}</h1>
          <p className="description">{t("about.p1")}</p>
        </header>
        <h2>{t("about.h2")}</h2>
        <p>{t("about.p2")}</p>
        <ul>
          <li>{t("about.list1")}</li>
          <li>{t("about.list2")}</li>
          <li>{t("about.list3")}</li>
        </ul>
        <p>
          <Trans i18nKey="about.p3">
            While there is no guarantee that prices may appreciate, many name
            squatters have also started “investing” in custom EOS account names.
            The ENS Marketplace will allow you to sell custom EOS account names
            to anyone at a fixed price or in an EOS auction. The ENS Marketplace
            will launch very soon! Please subscribe to our&nbsp;
            <a
              className="link"
              href="https://t.me/eosnameservice"
              target="_blank"
              rel="noopener noreferrer"
            >
              Telegram Announcements Channel
            </a>
            &nbsp;to stay up to date with the EOS Name Service.
          </Trans>
        </p>
        <p>
          <Trans i18nKey="about.cta">
            Ready to create a new EOS account with a custom EOS account
            name?&nbsp;
            <Link className="link" to="/">
              Create an EOS account now.
            </Link>
          </Trans>
        </p>
        <div className="about-us-youtube-container">
          <div className="about-us-youtube-container">
            <div className="ui active embed">
              <div className="embed">
                <iframe
                  allowFullScreen={true}
                  frameBorder="0"
                  height="100%"
                  scrolling="no"
                  src={overrideAutoPlay(
                    "https://www.youtube.com/embed/gqS1sXf7DBU?autoplay=1&rel=0&enablejsapi=1&origin=https%3A%2F%2Fwww.eosnameservice.io&widgetid=1"
                  )}
                  title="Embedded content"
                  width="100%"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  style={{ padding: 10 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      return {};
    },
    dispatch => {
      return {
        setPageTitle: args => {
          dispatch(setPageTitleRoutine(args));
        }
      };
    }
  )(withTranslation()(About))
);
