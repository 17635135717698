import { put, takeLatest } from "redux-saga/effects";
import * as dfuseActions from "./dfuseActions";
import * as dfuseClientApi from "./dfuseClientApi";
import * as scatterApi from "../scatter/scatterClientApi";

export const dfuseWatches = [
  getMarketPlaceNamesWatch,
  getPartnersWatch,
  getBidnameAuctionDataWatch
];

export function* getMarketPlaceNamesWatch() {
  yield takeLatest(
    dfuseActions.getMarketPlaceNamesRoutine.TRIGGER,
    getMarketPlaceNames
  );
}

export function* getPartnersWatch() {
  yield takeLatest(dfuseActions.getPartnersRoutine.TRIGGER, getPartners);
}

export function* getBidnameAuctionDataWatch() {
  yield takeLatest(
    dfuseActions.getBidnameAuctionDataRoutine.TRIGGER,
    getBidnameAuctionData
  );
}

function* getMarketPlaceNames() {
  //const response = yield dfuseClientApi.getMarketPlaceNames();
  const accountRows = [];
  const auctionRows = [];
  let moreAccounts = true;
  let moreAuctions = true;
  let auctionsLowerBound = "";
  let accountsLowerBound = "";

  while (moreAccounts) {
    const accountsResponse = yield scatterApi.getTableRows({
      code: "market.xyz",
      scope: "market.xyz",
      table: "accounts",
      lower_bound: accountsLowerBound,
      limit: 100
    });
    accountRows.push(...accountsResponse.rows);
    moreAccounts = accountsResponse.more;
    accountsLowerBound = accountsResponse.next_key;
  }

  while (moreAuctions) {
    const auctionsResponse = yield scatterApi.getTableRows({
      code: "market.xyz",
      scope: "market.xyz",
      table: "auctions",
      lower_bound: auctionsLowerBound,
      limit: 100
    });
    auctionRows.push(...auctionsResponse.rows);
    moreAuctions = auctionsResponse.more;
    auctionsLowerBound = auctionsResponse.next_key;
  }

  yield put(
    dfuseActions.getMarketPlaceNamesRoutine.success({
      accountsResponse: accountRows,
      auctionsResponse: auctionRows
    })
  );
}

function* getBidnameAuctionData() {
  const bidnameComResponse = yield scatterApi.getTableRows({
    code: "bidname.com",
    scope: "1",
    table: "order",
    index_position: 2,
    json: true,
    key_type: "name",
    reverse: false,
    limit: 10
  });

  const bidnameBankResponse = yield scatterApi.getTableRows({
    code: "bidname.com",
    scope: "2",
    table: "order",
    index_position: 2,
    json: true,
    key_type: "name",
    reverse: false,
    limit: 3
  });

  yield put(
    dfuseActions.getBidnameAuctionDataRoutine.success({
      bidnameCom: bidnameComResponse.rows,
      bidnameBank: bidnameBankResponse.rows
    })
  );
}

function* getPartners() {
  const response = yield dfuseClientApi.getPartners();
  yield put(dfuseActions.getPartnersRoutine.success(response));
}
