import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Form, Divider } from "semantic-ui-react";
import { registerFormHandler } from "../userActions";

class RegisterForm extends Component {
  render() {
    const { handleSubmit } = this.props; // handleSubmit is provided by reduxForm

    return (
      <Form onSubmit={handleSubmit(registerFormHandler)}>
        <div className="modalFormFieldDiv">
          <label htmlFor="email">Email</label>
          <Field
            component="input"
            type="text"
            name="email"
            placeholder="Email"
            autoComplete="off"
          />
        </div>
        <div className="modalFormFieldDiv">
          <label htmlFor="username">
            EOS Name - This is where your funds will go
          </label>
          <Field
            component="input"
            type="text"
            name="username"
            placeholder="Username"
            autoComplete="off"
          />
        </div>
        <div className="modalFormFieldDiv">
          <label htmlFor="password">Password</label>
          <Field
            className="modalInputDiv"
            component="input"
            type="password"
            name="password"
            placeholder="Password"
            autoComplete="off"
          />
        </div>
        <Divider hidden />
        <Button type="submit">Register</Button>
      </Form>
    );
  }
}

export default withRouter(
  connect((state, ownProps) => {
    const { role } = ownProps.match.params;
    return {
      initialValues: {
        role
      }
    };
  })(
    reduxForm({
      form: "RegisterForm"
    })(RegisterForm)
  )
);
