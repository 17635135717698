/* eslint-disable consistent-return */
/* eslint-disable react/button-has-type */
import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import * as modalActions from "../../modals/modalActions";
import * as cartActions from "../../cart/cartActions";

class EosSuccessModal extends Component {
  render() {
    const {
      showEosAccountCreationSuccessModal,
      closeEosSuccessModal,
      scatterSuccessMessage,
      cartItems
    } = this.props;

    const renderPurchasedNames = nameItems =>
      nameItems.map(item => {
        return (
          <li key={item.get("account_name")}>{item.get("account_name")}</li>
        );
      });

    return (
      <Modal
        size="small"
        open={showEosAccountCreationSuccessModal}
        onClose={closeEosSuccessModal}
      >
        <Modal.Header>EOS Account Creation Complete</Modal.Header>
        <Modal.Content>
          You have successfully purchased the following name(s):
          <ul>{cartItems && renderPurchasedNames(cartItems.toList())}</ul>
          <br />
          {scatterSuccessMessage}
        </Modal.Content>
      </Modal>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const showEosAccountCreationSuccessModal = state.modal.get(
        "showEosAccountCreationSuccessModal"
      );
      const scatterSuccessMessage = state.wallet.get("txSuccess");
      const cartItems = state.cart.get("names");
      return {
        showEosAccountCreationSuccessModal,
        scatterSuccessMessage,
        cartItems
      };
    },
    dispatch => {
      return {
        closeEosSuccessModal: args => {
          dispatch(modalActions.closeEosSuccessModalRoutine());
          dispatch(cartActions.emptyCartRoutine());
          dispatch(modalActions.closeCartModalRoutine());
        }
      };
    }
  )(withTranslation()(EosSuccessModal))
);
