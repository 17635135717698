import { fromJS, Map } from "immutable";
import * as cartActions from "./cartActions";
import {
  addEos,
  subtractEos,
  addUsd,
  subtractUsd
} from "../pricing/pricingUtil";

const defaultState = fromJS({
  names: Map(),
  //TODO: Might need to change these to strings
  totalEosCost: "0.0000",
  totalUsdCost: "0.00",
  totalRegEosCost: "0.0000",
  totalRegUsdCost: "0.00",
  cartMultiEligible: false
});

export default function reducer(state = defaultState, action) {
  const { payload } = action;

  switch (action.type) {
    case cartActions.addNameToCartRoutine.TRIGGER: {
      const existingName = state.getIn(["names", payload.account_name]);
      if (existingName) {
        // name already exists in cart so just return
        return state;
      }
      const newEosTotal = addEos(state.get("totalEosCost"), payload.eosPrice);
      const newUsdTotal = addUsd(state.get("totalUsdCost"), payload.usdPrice);
      const newEosRegTotal = addEos(
        state.get("totalRegEosCost"),
        payload.regEosPrice
      );
      const newUsdRegTotal = addUsd(
        state.get("totalRegUsdCost"),
        payload.regUsdPrice
      );
      const cartMultiEligible =
        state.get("cartMultiEligible") === true ||
        payload.suffixInfo.cypherglass_owned === true;
      return state
        .setIn(["names", payload.account_name], fromJS(payload))
        .set("cartMultiEligible", cartMultiEligible)
        .set("totalEosCost", newEosTotal)
        .set("totalUsdCost", newUsdTotal)
        .set("totalRegEosCost", newEosRegTotal)
        .set("totalRegUsdCost", newUsdRegTotal);
    }

    case cartActions.removeNameFromCartRoutine.TRIGGER: {
      const newEosTotal = subtractEos(
        state.get("totalEosCost"),
        payload.eosPrice
      );
      const newUsdTotal = subtractUsd(
        state.get("totalUsdCost"),
        payload.usdPrice
      );
      const newEosRegTotal = subtractEos(
        state.get("totalRegEosCost"),
        payload.regEosPrice
      );
      const newUsdRegTotal = subtractUsd(
        state.get("totalRegUsdCost"),
        payload.regUsdPrice
      );
      return state
        .deleteIn(["names", payload.account_name])
        .set("totalEosCost", newEosTotal)
        .set("totalUsdCost", newUsdTotal)
        .set("totalRegEosCost", newEosRegTotal)
        .set("totalRegUsdCost", newUsdRegTotal);
    }

    case cartActions.emptyCartRoutine.TRIGGER: {
      return state
        .set("names", Map())
        .set("cartMultiEligible", false)
        .set("totalEosCost", "0.0000")
        .set("totalUsdCost", "0.00")
        .set("totalRegEosCost", "0.0000")
        .set("totalRegUsdCost", "0.00");
    }

    default:
      return state;
  }
}
