import { fromJS } from "immutable";
import * as adminActions from "./adminActions";

const defaultState = fromJS({
  loadingSalesData: false,
  salesData: undefined,
  referralsData: undefined
});

export default function reducer(state = defaultState, action) {
  const { payload } = action;

  switch (action.type) {
    case adminActions.fetchSalesDataRoutine.TRIGGER: {
      return state
        .set("loadingSalesData", true)
        .set("salesData", fromJS(undefined))
        .set("salesBySuffix", fromJS(undefined))
        .set("salesTotals", fromJS(undefined));
    }

    case adminActions.fetchSalesDataRoutine.SUCCESS: {
      return state
        .set("loadingSalesData", false)
        .set("salesData", fromJS(payload.data.sales))
        .set("salesBySuffix", fromJS(payload.data.salesBySuffix))
        .set("salesTotals", fromJS(payload.data.salesTotals[0]));
    }

    default:
      return state;
  }
}
