import { put, takeLatest } from "redux-saga/effects";
import * as scatterActions from "./scatterActions";
import * as walletActions from "../wallet/walletActions";
import {
  buyAccount,
  placeBid,
  startAuction,
  sellAccount,
  cancelAuction,
  bidnameBid,
  buyAuctionAccount,
  updateSale,
  cancelSale,
  createAccountsWithEos
} from "../wallet/walletSaga";

export const scatterWatches = [
  sellAccountWatch,
  buyAccountWatch,
  buyAuctionAccountWatch,
  updateSaleWatch,
  cancelSaleWatch,
  startAuctionWatch,
  cancelAuctionWatch,
  createAccountsWithEosWatch,
  placeBidWatch,
  bidnameBidWatch,
  walletWatch
];

export function* walletWatch() {
  yield takeLatest(walletActions.selectProviderRoutine.success, signedInWallet);
}

function* signedInWallet(x) {
  yield put(scatterActions.signInToScatterRoutine.success(x.payload));
}

export function* sellAccountWatch() {
  yield takeLatest(scatterActions.sellAccountRoutine.TRIGGER, sellAccount);
}

export function* startAuctionWatch() {
  yield takeLatest(scatterActions.startAuctionRoutine.TRIGGER, startAuction);
}

export function* placeBidWatch() {
  yield takeLatest(scatterActions.bidAuctionRoutine.TRIGGER, placeBid);
}

export function* bidnameBidWatch() {
  yield takeLatest(scatterActions.bidnameBidRoutine.TRIGGER, bidnameBid);
}

export function* buyAccountWatch() {
  yield takeLatest(scatterActions.buyAccountRoutine.TRIGGER, buyAccount);
}

export function* buyAuctionAccountWatch() {
  yield takeLatest(
    scatterActions.buyAuctionAccountRoutine.TRIGGER,
    buyAuctionAccount
  );
}

export function* updateSaleWatch() {
  yield takeLatest(scatterActions.updateSaleRoutine.TRIGGER, updateSale);
}

export function* cancelSaleWatch() {
  yield takeLatest(scatterActions.cancelSaleRoutine.TRIGGER, cancelSale);
}

export function* cancelAuctionWatch() {
  yield takeLatest(scatterActions.cancelAuctionRoutine.TRIGGER, cancelAuction);
}

export function* createAccountsWithEosWatch() {
  yield takeLatest(
    scatterActions.createAccountsWithEosRoutine,
    createAccountsWithEos
  );
}

// const parseScatterResponse = resp => {
//   try {
//     const retResp = JSON.parse(resp);
//     if (retResp.isError && retResp.type === "identity_missing") {
//       retResp.error = {
//         details: [{ message: "Please login and retry" }]
//       };
//     }
//     return retResp;
//   } catch (e) {
//     if (
//       resp.isError &&
//       (resp.type === "identity_missing" || resp.type === "account_missing")
//     ) {
//       resp.error = { details: [{ message: "Please login and retry" }] };
//     }
//     return resp;
//   }
// };

// function* sellAccount({ payload }) {
//   const { sellAcctName, paymentAcct, sellAmount } = payload.values;
//   const sellAmountWithSymbol = sellAmount.includes(" EOS")
//     ? sellAmount
//     : `${sellAmount} EOS`;
//   try {
//     const rawResp = yield scatterClientApi.sellAccount({
//       acctName: sellAcctName,
//       amount: sellAmountWithSymbol,
//       payAcct: paymentAcct,
//       msg: ""
//     });
//     const resp = parseScatterResponse(rawResp);
//     if (resp.error) {
//       yield put(
//         scatterActions.sellAccountRoutine.failure(resp.error.details[0].message)
//       );
//     } else {
//       yield put(scatterActions.sellAccountRoutine.success(resp.transaction_id));
//     }
//   } catch (e) {
//     yield put(scatterActions.sellAccountRoutine.failure(e));
//   }
// }

// function* startAuction({ payload }) {
//   try {
//     const {
//       auctionAcctName,
//       paymentAcct,
//       buyNowPrice,
//       reservePrice
//     } = payload.values;
//     const buyNowPriceWithSymbol = buyNowPrice.includes(" EOS")
//       ? buyNowPrice
//       : `${buyNowPrice} EOS`;
//     const reservePriceWithSymbol = reservePrice.includes(" EOS")
//       ? reservePrice
//       : `${reservePrice} EOS`;
//     const rawResp = yield scatterClientApi.startAuction({
//       acctName: auctionAcctName,
//       buyprice: buyNowPriceWithSymbol,
//       reserve: reservePriceWithSymbol,
//       payAcct: paymentAcct
//     });
//     const resp = parseScatterResponse(rawResp);
//     if (resp.error) {
//       yield put(
//         scatterActions.startAuctionRoutine.failure(
//           resp.error.details[0].message
//         )
//       );
//     } else {
//       yield put(
//         scatterActions.startAuctionRoutine.success(resp.transaction_id)
//       );
//     }
//   } catch (e) {
//     console.error(e);
//     yield put(scatterActions.startAuctionRoutine.failure(e));
//   }
// }

// function* cancelAuction({ payload }) {
//   const { account4auction, key } = payload.values;
//   try {
//     const rawResp = yield scatterClientApi.cancelAuction({
//       account4auction,
//       owner_key_str: key,
//       active_key_str: key
//     });
//     const resp = parseScatterResponse(rawResp);
//     if (resp.error) {
//       yield put(
//         scatterActions.cancelAuctionRoutine.failure(
//           resp.error.details[0].message
//         )
//       );
//     } else {
//       yield put(
//         scatterActions.cancelAuctionRoutine.success(resp.transaction_id)
//       );
//     }
//   } catch (err) {
//     console.error(err);
//     yield put(scatterActions.cancelAuctionRoutine.failure(err));
//   }
// }

// function* placeBid({ payload }) {
//   const { account4auction, amount, key } = payload.values;
//   const amountWithSymbol = amount.includes(" EOS") ? amount : `${amount} EOS`;
//   try {
//     const rawResp = yield scatterClientApi.createTransferToPlaceBid({
//       account4auction,
//       amount: amountWithSymbol,
//       owner_key_str: key,
//       active_key_str: key
//     });
//     const resp = parseScatterResponse(rawResp);
//     if (resp.error) {
//       yield put(
//         scatterActions.bidAuctionRoutine.failure(resp.error.details[0].message)
//       );
//     } else {
//       yield put(scatterActions.bidAuctionRoutine.success(resp.transaction_id));
//     }
//   } catch (err) {
//     console.error(err);
//     yield put(scatterActions.bidAuctionRoutine.failure(err));
//   }
// }

// function* buyAccount({ payload }) {
//   const { buyAcctName, amount, key } = payload.values;
//   try {
//     const rawResp = yield scatterClientApi.createTransferToBuyMarketplaceAcct({
//       to: "market.xyz",
//       amount,
//       buyAcctName,
//       memo: `sp:${buyAcctName},${key},${key}`
//     });
//     const resp = parseScatterResponse(rawResp);
//     if (resp.error) {
//       yield put(
//         scatterActions.buyAccountRoutine.failure(resp.error.details[0].message)
//       );
//     } else {
//       yield put(scatterActions.buyAccountRoutine.success(resp.transaction_id));
//     }
//   } catch (err) {
//     yield put(scatterActions.buyAccountRoutine.failure(err));
//   }
// }

// function* bidnameBid({ payload }) {
//   const { bidAcctName, amount } = payload.values;
//   try {
//     const rawResp = yield scatterClientApi.createTransferToBidBidname({
//       to: "bidname.com",
//       amount,
//       bidAcctName
//     });
//     const resp = parseScatterResponse(rawResp);
//     if (resp.error) {
//       yield put(
//         scatterActions.bidnameBidRoutine.failure(resp.error.details[0].message)
//       );
//     } else {
//       yield put(scatterActions.bidnameBidRoutine.success(resp.transaction_id));
//     }
//   } catch (err) {
//     yield put(scatterActions.bidnameBidRoutine.failure(err));
//   }
// }

// function* buyAuctionAccount({ payload }) {
//   const { account4auction, amount, key } = payload.values;
//   const amountWithSymbol = amount.includes(" EOS") ? amount : `${amount} EOS`;
//   try {
//     const rawResp = yield scatterClientApi.createTransferToBuyAuctionAccount({
//       account4auction,
//       amount: amountWithSymbol,
//       owner_key_str: key,
//       active_key_str: key
//     });
//     const resp = parseScatterResponse(rawResp);
//     if (resp.error) {
//       yield put(
//         scatterActions.buyAuctionAccountRoutine.failure(
//           resp.error.details[0].message
//         )
//       );
//     } else {
//       yield put(
//         scatterActions.buyAuctionAccountRoutine.success(resp.transaction_id)
//       );
//     }
//   } catch (err) {
//     console.error(err);
//     yield put(scatterActions.buyAuctionAccountRoutine.failure(err));
//   }
// }

// function* updateSale({ payload }) {
//   const { sellAcctName, sellAmount } = payload.values;
//   const sellAmountWithSymbol = sellAmount.includes(" EOS")
//     ? sellAmount
//     : `${sellAmount} EOS`;
//   try {
//     const rawResp = yield scatterClientApi.updateSale({
//       sellAcctName,
//       sellAmount: sellAmountWithSymbol,
//       msg: ""
//     });
//     const resp = parseScatterResponse(rawResp);
//     if (resp.error) {
//       yield put(
//         scatterActions.updateSaleRoutine.failure(resp.error.details[0].message)
//       );
//     } else {
//       yield put(scatterActions.updateSaleRoutine.success(resp.transaction_id));
//     }
//   } catch (err) {
//     yield put(scatterActions.updateSaleRoutine.failure(err));
//   }
// }

// function* cancelSale({ payload }) {
//   const { sellAcctName, key } = payload.values;
//   try {
//     const rawResp = yield scatterClientApi.cancelSale({
//       account4sale: sellAcctName,
//       owner_key_str: key,
//       active_key_str: key
//     });
//     const resp = parseScatterResponse(rawResp);
//     if (resp.error) {
//       yield put(
//         scatterActions.cancelSaleRoutine.failure(resp.error.details[0].message)
//       );
//     } else {
//       yield put(scatterActions.cancelSaleRoutine.success(resp.transaction_id));
//     }
//   } catch (err) {
//     yield put(scatterActions.cancelSaleRoutine.failure(err));
//   }
// }

// function* createAccountsWithEos({ payload }) {
//   try {
//     const eosResult = yield scatterClientApi.createPremiumAccounts(payload);
//     if (eosResult.processed) {
//       yield put(
//         scatterActions.buyAccountRoutine.success(eosResult.processed.id)
//       );
//       yield put(modalActions.openEosSuccessModalRoutine.trigger());
//     } else {
//       yield put(scatterActions.buyAccountRoutine.failure(eosResult));
//       yield put(modalActions.openEosFailureModalRoutine.trigger());
//     }
//   } catch (e) {
//     console.error(JSON.stringify(e));
//     yield put(scatterActions.buyAccountRoutine.failure(e.message));
//     yield put(modalActions.openEosFailureModalRoutine.trigger());
//   }
// }
