/* eslint-disable react/destructuring-assignment */
import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

class renderDatePicker extends React.Component {
  static defaultProps = {
    placeholder: ""
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    this.props.input.onChange(moment(date).format("YYYY-MM-DD"));
  }

  render() {
    const {
      input,
      placeholder,
      meta: { touched, error }
    } = this.props;

    return (
      <div>
        <DatePicker
          {...input}
          placeholder={placeholder}
          onChange={this.handleChange}
          dateFormat="yyyy-MM-dd"
        />
        {touched && error && <span>{error}</span>}
      </div>
    );
  }
}

export default renderDatePicker;
