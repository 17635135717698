/* eslint-disable prefer-destructuring */
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class Title extends Component {
  constructor(props) {
    super(props);
    this.titleEl = document.getElementsByTagName("title")[0];
  }

  render() {
    const { pageTitle } = this.props;

    return ReactDOM.createPortal(pageTitle, this.titleEl);
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const pageTitle = state.common.get("pageTitle");
      return {
        pageTitle
      };
    },
    dispatch => {
      return {};
    }
  )(withTranslation()(Title))
);
