import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enStrings from "./locales/en";
import cnStrings from "./locales/cn-simplified";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: enStrings,
  cn: cnStrings
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    ns: "ens",
    debug: true,

    //keySeparator: true, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
