//http://localhost:3000/api/admin/sales?suffix=tp&end_date=2019-04-10T00:00:00.000Z&start_date=2019-04-10T00:00:00.000Z
import axios from "axios";
import querystring from "query-string";
//import * as adminActions from './adminActions';

export function* getSalesData(payload) {
  const query = querystring.stringify(payload);
  //return axios.get(`/api/applications${query?'?'+query:''}`, payload);
  const ensResponse = yield axios.get(
    `/api/admin/sales${query ? "?" + query : ""}`
  );
  return ensResponse;
}
