import { fromJS } from "immutable";
import * as commonActions from "./commonActions";

const defaultState = fromJS({
  pageTitle: "EOS Name Service"
});

export default function reducer(state = defaultState, action) {
  const { payload } = action;

  switch (action.type) {
    case commonActions.setPageTitleRoutine.TRIGGER: {
      return state.set("pageTitle", payload);
    }

    default:
      return state;
  }
}
