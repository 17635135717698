import React, { Component } from "react";
import { Grid, Table } from "semantic-ui-react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as dfuseActions from "../dfuse/dfuseActions";

class Partners extends Component {
  componentWillMount() {
    const { getPartners } = this.props;
    getPartners();
  }

  render() {
    const { history, partners } = this.props;
    const displayPartnersRows = partnersData =>
      partnersData.map(partner => {
        return (
          <Table.Row>
            <Table.Cell>{partner.get("username")}</Table.Cell>
            <Table.Cell>{partner.get("email")}</Table.Cell>
            <Table.Cell>{partner.get("role")}</Table.Cell>
            <Table.Cell>{partner.get("percent")}</Table.Cell>
            <Table.Cell>
              <a
                style={{ color: "#ff4536" }}
                target="_blank"
                rel="noopener noreferrer"
                href={`https://bloks.io/account/ens.xyz?loadContract=true&tab=Actions&scope=ens.xyz&limit=100&action=upsertpartnr&account=${partner.get(
                  "username"
                )}`}
              >
                Edit
              </a>{" "}
              <a
                style={{ color: "#ff4536" }}
                target="_blank"
                rel="noopener noreferrer"
                href={`https://bloks.io/account/ens.xyz?loadContract=true&tab=Actions&scope=ens.xyz&limit=100&action=erasepartner&account=${partner.get(
                  "username"
                )}`}
              >
                Delete
              </a>
            </Table.Cell>
          </Table.Row>
        );
      });

    return (
      <div className="partner-page">
        <h1>Partners</h1>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>EOS Name</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Role</Table.HeaderCell>
              <Table.HeaderCell>Percent</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{partners && displayPartnersRows(partners)}</Table.Body>
        </Table>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const { role } = ownProps.match.params;
      return {
        role,
        partners: state.dfuse.get("partners")
      };
    },
    dispatch => {
      return {
        getPartners: args => {
          dispatch(dfuseActions.getPartnersRoutine(args));
        }
      };
    }
  )(Partners)
);
