/* eslint-disable react/button-has-type */
import React, { Component } from "react";
import { Image, Menu, Button, Grid, Icon } from "semantic-ui-react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

export default () => (
  <div className="footer">
    <Link to="/terms" className="terms-conditions">
      Terms &amp; Conditions
    </Link>{" "}
    |{" "}
    <Link to="/privacy" className="terms-conditions">
      Privacy Policy
    </Link>
  </div>
);
