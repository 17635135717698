import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import "./App.scss";
import { createDfuseClient } from "@dfuse/client";
import * as userActions from "./user/userActions";
import * as walletActions from "./wallet/walletActions";
import ResponsiveContainer from "./container/ResponsiveContainer";
import "./i18n";

export const client = createDfuseClient({
  apiKey: "web_5e1e427351e827e5534cf3721ba16e23",
  network: "mainnet"
});

class App extends Component {
  componentWillMount() {
    const { loginFromLocal, user, initWallet, getProviders } = this.props;
    console.log("now calling init..");
    initWallet();
    getProviders();
    if (!user) {
      const userFromLocal = localStorage.getItem("user");
      if (userFromLocal) {
        loginFromLocal(JSON.parse(userFromLocal));
      }
    }
  }

  render() {
    const { current_user, role } = this.props;
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/admin/login" component={ResponsiveContainer} />
          <Route path="/admin">
            {role === "admin" ? <ResponsiveContainer /> : <Redirect to="/" />}
          </Route>
          <Route path="/" component={ResponsiveContainer} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default connect(
  (state, ownProps) => {
    const current_user = state.user.get("user");
    return {
      current_user,
      role: state.user.get("role")
    };
  },
  dispatch => ({
    loginFromLocal: user => dispatch(userActions.loginRoutine.success(user)),
    initWallet: () => dispatch(walletActions.initWalletRoutine.trigger()),
    getProviders: () => dispatch(walletActions.getProvidersRoutine.trigger())
  })
)(App);
