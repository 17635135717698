import { fromJS } from "immutable";
import * as dfuseActions from "./dfuseActions";

const defaultState = fromJS({
  marketplaceNames: [],
  bidnameComData: [],
  bidnameBankData: [],
  partners: []
});

export default function reducer(state = defaultState, action) {
  const { payload } = action;

  switch (action.type) {
    case dfuseActions.getMarketPlaceNamesRoutine.SUCCESS: {
      return state.set(
        "marketplaceNames",
        fromJS([...payload.accountsResponse, ...payload.auctionsResponse])
      );
    }

    case dfuseActions.getBidnameAuctionDataRoutine.SUCCESS: {
      return state
        .set("bidnameComData", fromJS(payload.bidnameCom))
        .set("bidnameBankData", fromJS(payload.bidnameBank));
    }

    case dfuseActions.getPartnersRoutine.SUCCESS: {
      return state.set("partners", fromJS(payload));
    }

    default:
      return state;
  }
}
