import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import queryString from "query-string";
import moment from "moment";
import Countdown from "react-countdown";
import { setPageTitleRoutine } from "../common/commonActions";
import { getBidnameAuctionDataRoutine } from "../dfuse/dfuseActions";

class Pricing extends Component {
  componentWillMount() {
    const { setPageTitle, getBidnameAuctionData } = this.props;
    setPageTitle("Pricing | EOS Name Service | Create an EOS account now!");
    getBidnameAuctionData();
  }

  render() {
    const {
      rate,
      prices,
      referrer,
      t,
      bidnameComData,
      bidnameBankData
    } = this.props;

    function getEOS(amount, discount) {
      const eos = (parseFloat(amount.split(" ")[0]) * discount).toFixed(4);
      return eos;
    }
    function getUSD(amount, discount) {
      const eos = parseFloat(getEOS(amount, discount));
      return (eos * rate.getIn(["exchangeRate", "rate"])).toFixed(2);
    }

    const loading = <div>Loading</div>;

    return (
      <div className="page-contents">
        <header>
          <h1 className="title">{t("pricing.title")}</h1>
          <p className="description">{t("pricing.subtitle")}</p>
        </header>
        <h2>{t("pricing.fixedPriceTitle")}</h2>
        <div className="m-b-24">
          <Trans i18nKey="pricing.fixedPriceInfo">
            <p>
              Most EOS account names on EOS Name Service are registered using a
              fixed price system. That means every name has a fixed price
              depending on the suffix and the length of the name. You can create
              any EOS account name with these suffixes and lengths instantly.
            </p>
            <p>
              Your new account will be created under your existing account keys,
              but these keys can be changed at any time using your favorite
              wallet.
            </p>
          </Trans>
        </div>
        <ul className="pricing">
          {rate &&
            prices &&
            prices
              .sortBy(val => {
                return val.getIn(["suffixInfo", "display_order"], 10000);
              })
              .map(price => {
                const discount =
                  referrer && referrer !== "none"
                    ? price.getIn(["suffixInfo", "base_discount"], 1)
                    : 1;
                return (
                  <li className="pricing-item" key={price.get("suffix")}>
                    <table className="pricing-table">
                      <tr>
                        <th>
                          <span className="pricing-unit">
                            <span className="pricing-unit-point">.</span>
                            {price.get("suffix")}
                          </span>
                        </th>
                        <th className="pricing-head-cell">EOS</th>
                        <th className="pricing-head-cell">USD</th>
                      </tr>
                      {price
                        .get("prices")
                        .sortBy(val => val.get("length"))
                        .map((item, index) => (
                          <tr key={price.get("suffix") + item.get("length")}>
                            <td>
                              {item.get("length")}{" "}
                              {item.get("length") > 1
                                ? t("pricing.pluralChar")
                                : t("pricing.singularChar")}
                            </td>
                            <td className="pricing-cell">
                              {getEOS(item.get("amount"), discount)}
                            </td>
                            <td className="pricing-cell">
                              ${getUSD(item.get("amount"), discount)}
                            </td>
                          </tr>
                        ))}
                    </table>
                  </li>
                );
              })}
          {(!rate || !prices) && loading}
        </ul>
        <h2 className="m-t-32">{t("pricing.auctionTitle")}</h2>
        <div className="m-b-24">
          <Trans i18nKey="pricing.auctionInfo">
            <p>
              COM .IO .EOS
              <br />
              If you are bidding on .COM .IO or .EOS, only the top bid (out of
              all .com .io and .eos names being bid on) is sold every 12 hours.
            </p>
            <p>
              You can cancel your account bids at any time as long as your
              account bid is not in the Top 50 highest bids of your auction
              block. If your account is in the Top 50 highest bids out of all
              .com .io and .eos names, you cannot receive a refund.
            </p>
            <p>
              .GAMES .BANK
              <br />
              If you are bidding on .GAMES or .BANK, only the top 3 bids (out of
              all names being bid on) have a chance to win their respective
              names. If you are the top bid in your auction block, you only have
              to remain the top bid for 12 hours. If you are the second highest
              bid, you only have to remain there for 18 hours. And if you are
              the third highest bid, you only have to remain there for 24 hours
              to win your name.
            </p>
            <p>
              If you are outbid and are not in the Top 3 bids out of all .games
              and .bank names being bid on, you will not have a chance to win
              the name and must bid higher to be in the Top 3 bids.
            </p>
            <p>
              You can cancel your account bids at any time as long as your
              account bid is not in the Top 50 highest bids of your auction
              block. If your account is in the Top 50 highest bids out of all
              .games and .bank names, you cannot receive a refund.
            </p>
            <p>
              AFTER YOU HAVE WON
              <br />
              Once you’ve won, simply search your name again and click on it to
              register it.
            </p>
            <p>
              Your new account will be created under your existing account keys,
              but these keys can be changed at any time using your favorite
              wallet.
            </p>
            <p>
              ACCESS THE SITE DIRECTLY
              <br />
              You can also access the Auction site directly at:
              http://stname.starteos.io/#/index to review names, bid on names,
              and perform other tasks related to these suffixes. We do not host
              this auction service and do not make the rules. We only integrate
              the ability to Bid and Claim names on EOS Name Service.
            </p>
            <p>
              EOS Name Service does not assume any liability for any errors with
              the auction system since we do not maintain it. The rules can
              change at any time.
            </p>
          </Trans>
        </div>
        <p>
          <ul className="pricing">
            <li className="pricing-item" key="com">
              <table className="pricing-table">
                <tbody>
                  <tr>
                    <th>
                      <span className="pricing-unit">
                        <span className="pricing-unit-point">.</span>
                        com
                        <br />
                        <span className="pricing-unit-point">.</span>
                        eos
                        <br />
                        <span className="pricing-unit-point">.</span>
                        io
                      </span>
                    </th>
                  </tr>
                  <tr key="fjfisjfids">
                    {bidnameComData && bidnameComData.size !== 0 && (
                      <td>
                        {`${bidnameComData.getIn([
                          0,
                          "name"
                        ])} ${bidnameComData.getIn([0, "bid"])} `}{" "}
                        <Countdown
                          date={
                            new Date(
                              Date.now() +
                                (43200000 -
                                  (Date.now() -
                                    bidnameComData.getIn([0, "update_time"]) /
                                      1000))
                            )
                          }
                        />
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </li>
            <li className="pricing-item" key="bank">
              <table className="pricing-table">
                <tbody>
                  <tr>
                    <th>
                      <span className="pricing-unit">
                        <span className="pricing-unit-point">.</span>
                        BANK
                        <br />
                        <span className="pricing-unit-point">.</span>
                        GAMES
                      </span>
                    </th>
                  </tr>
                  <tr key="fjfisjfids">
                    {bidnameBankData && bidnameBankData.size !== 0 && (
                      <td>
                        {`${bidnameBankData.getIn([
                          0,
                          "name"
                        ])} ${bidnameBankData.getIn([0, "bid"])} `}
                        <Countdown
                          date={
                            new Date(
                              Date.now() +
                                (43200000 -
                                  (Date.now() -
                                    bidnameBankData.getIn([0, "update_time"]) /
                                      1000))
                            )
                          }
                        />
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </li>
          </ul>
        </p>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const prices = state.pricing.get("prices");
      const rate = state.pricing.get("rate");
      const q = queryString.parse(ownProps.location.search);
      const { ref } = q;
      const bidnameComData = state.dfuse.get("bidnameComData");
      const bidnameBankData = state.dfuse.get("bidnameBankData");
      return {
        prices,
        rate,
        referrer: ref,
        bidnameComData,
        bidnameBankData
      };
    },
    dispatch => {
      return {
        setPageTitle: args => {
          dispatch(setPageTitleRoutine(args));
        },
        getBidnameAuctionData: () => {
          dispatch(getBidnameAuctionDataRoutine());
        }
      };
    }
  )(withTranslation()(Pricing))
);
