import { initAccessContext } from "eos-transit";
import scatter from "eos-transit-scatter-provider";
import lynx from "eos-transit-lynx-provider";
import anchor from "eos-transit-anchorlink-provider";

let wallet;

const accessContext = initAccessContext({
  appName: "ENS",
  network: {
    host: "nodes.get-scatter.com",
    port: 443,
    protocol: "https",
    chainId: "aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906"
  },
  walletProviders: [scatter(), lynx(), anchor()]
});

export function* getProviders() {
  const providers = yield accessContext.getWalletProviders();
  return providers;
}

export function* selectProvider({ payload }) {
  const provider = payload;
  const providers = yield getProviders();
  const providerObj = providers.find(x => x.id == provider);
  wallet = accessContext.initWallet(providerObj);
  yield wallet.connect();
  const res = yield wallet.login();

  localStorage.setItem("autoLogin", providerObj.id);

  const accountName = res.account_name;
  const activeKey = res.permissions
    .find(permission => permission.perm_name == "active")
    .required_auth.keys.find(key => key.weight == 1).key;
  return { activeKey, accountName };
}

export function* triggerActions(actions) {
  console.log(wallet, "is the wallet thing", actions);
  const auth = [
    { actor: wallet.auth.accountName, permission: wallet.auth.permission }
  ];

  const authAddedActions = actions.map(action =>
    action["authorization"] ? action : { ...action, authorization: auth }
  );

  const x = yield wallet.eosApi.transact(
    { actions: authAddedActions },
    {
      broadcast: true,
      blocksBehind: 3,
      expireSeconds: 60
    }
  );
  console.log(x, "came back from transaction");
  return x.transaction_id;
}

export function* createTransferToBuyMarketplaceAcct({
  amount,
  buyAcctName,
  key
}) {
  const publicKey = key;
  const actions = [
    {
      account: "eosio.token",
      name: "transfer",
      data: {
        from: wallet.auth.accountName,
        to: "ens.xyz",
        quantity: amount,
        memo: "Deposit"
      }
    },
    {
      account: "ens.xyz",
      name: "buyaccount",
      data: {
        creator: wallet.auth.accountName,
        name: buyAcctName,
        owner: {
          accounts: [],
          keys: [
            {
              key: publicKey,
              weight: 1
            }
          ],
          threshold: 1,
          waits: []
        },
        active: {
          accounts: [],
          keys: [
            {
              key: publicKey,
              weight: 1
            }
          ],
          threshold: 1,
          waits: []
        }
      }
    }
  ];
  return yield triggerActions(actions);
}

export function* startAuction({ acctName, buyprice, reserve, payAcct }) {
  const actions = [
    {
      account: "eosio",
      name: "updateauth",
      authorization: [{ actor: acctName, permission: "owner" }],
      data: {
        account: acctName,
        permission: "owner",
        parent: "",
        auth: {
          threshold: 1,
          keys: [],
          accounts: [
            {
              permission: {
                actor: "market.xyz",
                permission: "eosio.code"
              },
              weight: 1
            }
          ],
          waits: []
        }
      }
    },
    {
      account: "market.xyz",
      name: "startauction",
      authorization: [{ actor: acctName, permission: "owner" }],
      data: {
        account4auction: acctName,
        buyitnowprice: buyprice,
        reserve,
        paymentaccnt: payAcct
      }
    }
  ];
  return yield triggerActions(actions);
}

export function* cancelAuction({
  account4auction,
  owner_key_str,
  active_key_str
}) {
  const actions = [
    {
      account: "market.xyz",
      name: "cancelauct",
      data: {
        account4auction,
        owner_key_str,
        active_key_str
      }
    }
  ];
  return yield triggerActions(actions);
}

export function* createTransferToBidBidname({ to, amount, bidAcctName }) {
  const actions = [
    {
      account: "eosio.token",
      name: "transfer",
      data: {
        from: wallet.auth.accountName,
        to,
        quantity: amount,
        memo: `${bidAcctName},ravensharing`
      }
    }
  ];

  return yield triggerActions(actions);
}

export function* createTransferToBuyAuctionAccount({
  amount,
  account4auction,
  owner_key_str,
  active_key_str,
  referrer
}) {
  const ownerKey = owner_key_str;
  const activeKey = active_key_str;
  const actions = [
    {
      account: "eosio.token",
      name: "transfer",
      data: {
        from: wallet.auth.accountName,
        to: "market.xyz",
        quantity: amount,
        memo: `buynow-${account4auction}-${ownerKey}-${activeKey}-${referrer}`
      }
    }
  ];
  return yield triggerActions(actions);
}

export function* cancelSale({ account4sale, owner_key_str, active_key_str }) {
  const actions = [
    {
      account: "market.xyz",
      name: "cancel",
      data: {
        account4sale,
        owner_key_str,
        active_key_str
      }
    }
  ];
  return yield triggerActions(actions);
}

export function* createPremiumAccounts({
  cartItems,
  totalEosAmount,
  referrer
}) {
  const actions = [];
  console.log(cartItems, "are cart items");

  let discount = "";

  const createTransferAction = item => {
    const name = item.get("account_name");
    const amount = item.get("eosPrice");
    const newAccount =
      name.length === 12 && name.includes(".") === false ? "0" : "1";
    const to =
      newAccount !== "1" ? "ens.xyz" : item.getIn(["suffixInfo", "pay_to"]);
    const memoPattern = item.getIn(
      ["suffixInfo", "memo_pattern"],
      "**newAccount**-**eosName**-**eosPublicKey**-**eosPublicKey**-**refAccount**-**isPaypal**-**discount**"
    );
    const memo = memoPattern
      .replace("**newAccount**", newAccount)
      .replace("**eosName**", name)
      .replace(/\*\*eosPublicKey\*\*/g, wallet.auth.publicKey)
      .replace("**refAccount**", referrer !== "none" ? `${referrer}` : "")
      .replace("**isPaypal**", "0")
      .replace("**discount**", discount);

    const action = {
      account: "eosio.token",
      name: "transfer",
      data: {
        from: wallet.auth.accountName,
        to,
        quantity: `${amount} EOS`,
        memo
      }
    };
    discount = "multicart";
    return action;
  };

  cartItems.map(item => actions.push(createTransferAction(item)));

  return yield triggerActions(actions);
}

export function* updateSale({ sellAcctName, sellAmount, msg }) {
  const actions = [
    {
      account: "market.xyz",
      name: "update",
      data: {
        account4sale: sellAcctName,
        saleprice: sellAmount,
        message: msg
      }
    }
  ];
  return yield triggerActions(actions);
}

export function* sellAccount({ acctName, amount, payAcct, msg }) {
  const actions = [
    {
      account: "eosio",
      name: "updateauth",
      data: {
        account: acctName,
        permission: "owner",
        parent: "",
        auth: {
          threshold: 1,
          keys: [],
          accounts: [
            {
              permission: {
                actor: "market.xyz",
                permission: "eosio.code"
              },
              weight: 1
            }
          ],
          waits: []
        }
      }
    },
    {
      account: "market.xyz",
      name: "sell",
      authorization: [{ actor: acctName, permission: "owner" }],
      data: {
        account4sale: acctName,
        saleprice: amount,
        paymentaccnt: payAcct,
        message: msg
      }
    }
  ];
  return yield triggerActions(actions);
}

export function* createTransferToPlaceBid({ amount, account4auction }) {
  const ownerKey = "";
  const activeKey = "";
  const referrer = "";
  const actions = [
    {
      account: "eosio.token",
      name: "transfer",
      data: {
        from: wallet.auth.accountName,
        to: "market.xyz",
        quantity: amount,
        memo: `bid-${account4auction}-${ownerKey}-${activeKey}-${referrer}`
      }
    }
  ];
  return yield triggerActions(actions);
}

export function* logout() {
  localStorage.removeItem("autoLogin");
  return yield wallet.logout();
}
