/* eslint-disable react/button-has-type */
import React, { Component } from "react";
import {
  Menu,
  Dropdown,
  Icon,
  Popup,
  Modal,
  Button,
  Divider,
  Input
} from "semantic-ui-react";
import { withRouter, Link } from "react-router-dom";
import queryString from "query-string";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import * as scatterActions from "../scatter/scatterActions";
import * as modalActions from "../modals/modalActions";
import * as walletActions from "../wallet/walletActions";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { ReactComponent as TwitterLogo } from "../assets/images/twitter-logo.svg";
import { ReactComponent as TelegramLogo } from "../assets/images/telegram-logo.svg";

class FunnelHeader extends Component {
  componentDidMount() {
    const autoLogin = localStorage.getItem("autoLogin");
    if (autoLogin) {
      this.props.triggerAutoLogin(autoLogin);
    }
  }

  render() {
    const {
      identity,
      acctName,
      signOutOfScatter,
      openCartModal,
      i18n,
      t,
      referrer,
      openLoginModal,
      openReferralModal,
      widget,
      role
    } = this.props;
    const changeLanguage = lng => i18n.changeLanguage(lng);
    const gotoLogin = () => {
      this.closeReferral();
      this.showLogin();
    };
    return (
      <div className="desktop-header">
        {widget !== "true" && (
          <Menu stackable secondary>
            <Menu.Item>
              <Link to={`/${referrer !== "none" ? `?ref=${referrer}` : ""}`}>
                <Logo className="ens-header-logo" />
              </Link>
            </Menu.Item>
            <Menu.Menu
              className="header-menu"
              position={widget === "true" ? null : "right"}
            >
              <Menu.Item>
                <a
                  href="https://twitter.com/eosnameservice"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterLogo className="twitter-logo" />
                </a>
              </Menu.Item>
              <Menu.Item>
                <a
                  href="https://t.me/eosnameservice"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TelegramLogo className="telegram-logo" />
                </a>
              </Menu.Item>
              {/* <Menu.Item>
                <Link
                  to={`/marketplace${
                    referrer !== "none" ? `?ref=${referrer}` : ""
                  }`}
                >
                  {t("navigation.header.marketplace")}
                </Link>
              </Menu.Item> */}
              {/* <Menu.Item>
                <Link
                  to={`/pricing${
                    referrer !== "none" ? `?ref=${referrer}` : ""
                  }`}
                >
                  {t("navigation.header.pricing")}
                </Link>
              </Menu.Item> */}
              <Menu.Item>
                <Link
                  to={`/about${referrer !== "none" ? `?ref=${referrer}` : ""}`}
                >
                  {t("navigation.header.about")}
                </Link>
              </Menu.Item>
              <Menu.Item>
                <a href="mailto:support@eosnameservice.io">
                  {t("navigation.header.support")}
                </a>
              </Menu.Item>
              {/* <Menu.Item>
                <Link
                  className="referral-menu-item"
                  to="#"
                  onClick={openReferralModal}
                >
                  {t("navigation.header.referral")}
                </Link>
              </Menu.Item> */}
              {identity === null ? (
                <Menu.Item className="sign-in" onClick={openLoginModal}>
                  {t("navigation.header.login")}
                </Menu.Item>
              ) : (
                <Popup
                  content="Press to sign out"
                  trigger={
                    <Menu.Item
                      className="sign-in"
                      onClick={() => signOutOfScatter()}
                    >
                      <Icon name="user" /> {t("navigation.header.greeting")},{" "}
                      {acctName}
                    </Menu.Item>
                  }
                />
              )}
              {role === "admin" && (
                <Dropdown className="lang-select" item text="Admin">
                  <Dropdown.Menu>
                    <Dropdown.Item
                      as={Link}
                      to="/admin/sales"
                      className="lang-option"
                    >
                      Sales Reports
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Link}
                      to="/admin/promotions"
                      className="lang-option"
                    >
                      Promotions
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Link}
                      to="/admin/partners"
                      className="lang-option"
                    >
                      Partners
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <Dropdown
                className="lang-select"
                item
                text={t("navigation.header.currentlanguage")}
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    className="lang-option"
                    onClick={() => changeLanguage("en")}
                  >
                    English
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => changeLanguage("cn")}>
                    中文
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* <Menu.Item onClick={() => openCartModal()}>
                <Icon name="shopping cart" />
              </Menu.Item> */}
            </Menu.Menu>
          </Menu>
        )}
        {widget === "true" && (
          <Menu className="header-menu" compact stackable secondary>
            {/*
              <Menu.Item>
                <Link to={`/${referrer!=='none'?`?ref=${referrer}&widget=true`:'?widget=true'}`}>
                    Home
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to={`/marketplace${referrer!=='none'?`?ref=${referrer}&widget=true`:'?widget=true'}`}>{t('navigation.header.marketplace')}</Link>
              </Menu.Item>
              <Menu.Item>
                <Link to={`/pricing${referrer!=='none'?`?ref=${referrer}&widget=true`:'?widget=true'}`}>{t('navigation.header.pricing')}</Link>
              </Menu.Item>
              */}
            {identity === null ? (
              <Menu.Item className="sign-in" onClick={openLoginModal}>
                {t("navigation.header.login")}
              </Menu.Item>
            ) : (
              <Popup
                content="Press to logout"
                trigger={
                  <Menu.Item
                    className="sign-in"
                    onClick={() => signOutOfScatter()}
                  >
                    <Icon name="user" /> {t("navigation.header.greeting")},{" "}
                    {acctName}
                  </Menu.Item>
                }
              />
            )}
            <Dropdown
              className="lang-select"
              item
              text={t("navigation.header.currentlanguage")}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  className="lang-option"
                  onClick={() => changeLanguage("en")}
                >
                  English
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeLanguage("cn")}>
                  中文
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const acctName = state.wallet.get("isAuthenticated");
      const identity = acctName || null;
      const q = queryString.parse(ownProps.location.search);
      const { ref, widget } = q;
      return {
        acctName,
        identity,
        referrer: ref ? ref : "none",
        widget,
        role: state.user.get("role")
      };
    },
    dispatch => {
      return {
        signOutOfScatter: args => {
          dispatch(walletActions.logoutRoutine());
        },
        triggerAutoLogin: providerId => {
          dispatch(walletActions.selectProviderRoutine(providerId));
        },
        openLoginModal: args => {
          dispatch(modalActions.openLoginModalRoutine());
        },
        openReferralModal: args => {
          dispatch(modalActions.openReferralModalRoutine());
        },
        openCartModal: args => {
          dispatch(modalActions.openCartModalRoutine(args));
        }
      };
    }
  )(withTranslation()(FunnelHeader))
);
