export const computeNamePrice = () => {
  return "0.0000 EOS";
};

export function calculateEosPrice(amount, discount) {
  const eos = (parseFloat(amount.split(" ")[0]) * discount).toFixed(4);
  return eos;
}

export function calculateUsdPrice(amount, discount, rate) {
  const eos = parseFloat(calculateEosPrice(amount, discount));
  return (eos * rate.getIn(["exchangeRate", "rate"])).toFixed(2);
}

export function getItemPrice(
  { account_name },
  prices,
  referrer,
  rate,
  cartItems,
  cartMultiEligible
) {
  if (account_name.length === 12 && account_name.includes(".") === false) {
    return {
      eos: "0.5000",
      usd: "4.99",
      regEosPrice: "0.5000",
      regUsdPrice: "4.99",
      suffixInfo: { display_order: 0, cypherglass_owned: false },
      multicartEligible: false,
      multicartApplied: false
    };
  }
  try {
    const pricesJSON = prices.toJSON();
    const prefix = account_name.split(".")[0];
    const suffix = account_name.split(".")[1];

    const suffixDO = pricesJSON.filter(item => item.suffix == suffix)[0];
    let salesDiscount = 0.0;
    let multicartDiscount = 0.0;
    if (cartMultiEligible) {
      multicartDiscount = 0.2;
    }

    const { activeSales } = suffixDO;
    if (
      activeSales &&
      activeSales.min_length <= prefix.length &&
      activeSales.max_length >= prefix.length
    ) {
      salesDiscount = activeSales.percent;
    }

    const price = suffixDO.prices.filter(
      item => prefix.length == item.length
    )[0];
    const discount =
      suffixDO.suffixInfo.cypherglass_owned !== true
        ? 1
        : referrer && referrer !== "none"
        ? (1 - multicartDiscount) *
          (1 - salesDiscount) *
          suffixDO.suffixInfo.base_discount
        : (1 - salesDiscount) * (1 - multicartDiscount);

    if (!price) return null;
    // const mockPrice = {
    //   regEosPrice: 3.34,
    //   regUsdPrice: 34,
    //   eos: 32,
    //   usd: 2,
    //   onSale: false,
    //   salesDiscount: 0.99,
    //   suffixInfo: {}
    // };
    // return mockPrice;
    return {
      regEosPrice: calculateEosPrice(price.amount, 1),
      regUsdPrice: calculateUsdPrice(price.amount, 1, rate),
      eos: calculateEosPrice(price.amount, discount),
      usd: calculateUsdPrice(price.amount, discount, rate),
      onSale: salesDiscount !== 0.0,
      salesDiscount,
      suffixInfo: suffixDO.suffixInfo
    };
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function addEos(val1, val2) {
  return (
    parseFloat(val1.split(" ")[0]) + parseFloat(val2.split(" ")[0])
  ).toFixed(4);
}

export function subtractEos(val1, val2) {
  return (
    parseFloat(val1.split(" ")[0]) - parseFloat(val2.split(" ")[0])
  ).toFixed(4);
}
export function addUsd(val1, val2) {
  return (parseFloat(val1) + parseFloat(val2)).toFixed(2);
}
export function subtractUsd(val1, val2) {
  return (parseFloat(val1) - parseFloat(val2)).toFixed(2);
}
