import { createRoutine, bindRoutineToReduxForm } from "redux-saga-routines";

// creating routine
export const openLoginModalRoutine = createRoutine("modals/OPEN_LOGIN_MODAL");
export const closeLoginModalRoutine = createRoutine("modals/CLOSE_LOGIN_MODAL");

export const openCartModalRoutine = createRoutine("modals/OPEN_CART_MODAL");
export const closeCartModalRoutine = createRoutine("modals/CLOSE_CART_MODAL");

export const openSellAccountModalRoutine = createRoutine(
  "modals/OPEN_SELL_ACCOUNT_MODAL"
);
export const closeSellAccountModalRoutine = createRoutine(
  "modals/CLOSE_SELL_ACCOUNT_MODAL"
);

export const openWalletDnsModalRoutine = createRoutine(
  "modals/OPEN_WALLET_DNS_MODAL"
);
export const closeWalletDnsModalRoutine = createRoutine(
  "modals/CLOSE_WALLET_DNS_MODAL"
);

export const openAuctionAccountModalRoutine = createRoutine(
  "modals/OPEN_AUCTION_ACCOUNT_MODAL"
);
export const closeAuctionAccountModalRoutine = createRoutine(
  "modals/CLOSE_AUCTION_ACCOUNT_MODAL"
);

export const openPlaceBidModalRoutine = createRoutine(
  "modals/OPEN_PLACE_BID_MODAL"
);
export const closePlaceBidModalRoutine = createRoutine(
  "modals/CLOSE_PLACE_BID_MODAL"
);

export const openBuyItNowModalRoutine = createRoutine(
  "modals/OPEN_BUY_IT_NOW_MODAL"
);
export const closeBuyItNowModalRoutine = createRoutine(
  "modals/CLOSE_BUY_IT_NOW_MODAL"
);

export const openCancelAuctionModalRoutine = createRoutine(
  "modals/OPEN_CANCEL_AUCTION_MODAL"
);
export const closeCancelAuctionModalRoutine = createRoutine(
  "modals/CLOSE_CANCEL_AUCTION_MODAL"
);

export const openReferralModalRoutine = createRoutine(
  "modals/OPEN_REFERRAL_MODAL"
);
export const closeReferralModalRoutine = createRoutine(
  "modals/CLOSE_REFERRAL_MODAL"
);

export const openPurchaseModalRoutine = createRoutine(
  "modals/OPEN_PURCHASE_MODAL"
);
export const closePurchaseModalRoutine = createRoutine(
  "modals/CLOSE_PURCHASE_MODAL"
);

export const openCreatingAccountModalRoutine = createRoutine(
  "modals/OPEN_CREATING_ACCOUNT_MODAL"
);
export const closeCreatingAccountModalRoutine = createRoutine(
  "modals/CLOSE_CREATING_ACCOUNT_MODAL"
);

export const openEosSuccessModalRoutine = createRoutine(
  "modals/OPEN_EOS_SUCCESS_MODAL"
);
export const closeEosSuccessModalRoutine = createRoutine(
  "modals/CLOSE_EOS_SUCCESS_MODAL"
);
export const openEosFailureModalRoutine = createRoutine(
  "modals/OPEN_EOS_FAILURE_MODAL"
);
export const closeEosFailureModalRoutine = createRoutine(
  "modals/CLOSE_EOS_FAILURE_MODAL"
);
