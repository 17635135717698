import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { Button, Form, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { salesDataFilterFormHandler } from "../adminActions";
import renderDatePicker from "../../forms/renderDatePicker";

class SalesDataFilterForm extends Component {
  render() {
    const { handleSubmit, t } = this.props; // handleSubmit is provided by reduxForm
    return (
      <Form onSubmit={handleSubmit(salesDataFilterFormHandler)}>
        <div className="modalFormFieldDiv">
          <label htmlFor="suffix">Suffix</label>
          <Field
            component="input"
            type="text"
            name="suffix"
            placeholder="Suffix"
            autoComplete="off"
          />
        </div>

        <div>
          <label htmlFor="start_date">Start Date</label>
          <Field name="start_date" component={renderDatePicker} />
        </div>
        <div>
          <label htmlFor="end_date">End Date</label>
          <Field name="end_date" component={renderDatePicker} />
        </div>

        <Divider hidden />
        <Button
          className="buy-account-submit-button"
          floated="right"
          type="submit"
        >
          Get Sales Data
        </Button>
        <Divider hidden />
      </Form>
    );
  }
}

export default withRouter(
  connect((state, ownProps) => {
    return {
      initialValues: {}
    };
  })(
    reduxForm({
      form: "SalesDataFilterForm"
    })(withTranslation()(SalesDataFilterForm))
  )
);
