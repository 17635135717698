/* eslint-disable react/button-has-type */
import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Countdown from "react-countdown";
import * as cartActions from "../../cart/cartActions";
import * as modalActions from "../../modals/modalActions";
import BidnameBidForm from "../../forms/BidnameBidForm";
import { addEos } from "../../pricing/pricingUtil";
import * as pricingClientApi from "../../pricing/pricingClientApi";

class BidNameCard extends Component {
  state = {
    stAvailable: "loading",
    blockchainAvailable: "loading"
  };

  componentDidMount() {
    const { item } = this.props;
    const { account_name } = item;
    pricingClientApi
      .searchBidname(account_name)
      .then(response =>
        this.setState({
          stAvailable:
            response.data.length === 0 || response.data[0].end_time === ""
        })
      )
      .catch(response => this.setState({ stAvailable: true }));
    pricingClientApi
      .searchName(account_name)
      .then(() => this.setState({ blockchainAvailable: false }))
      .catch(() => this.setState({ blockchainAvailable: true }));
  }

  render() {
    const { bidData, item, t } = this.props;
    const { stAvailable, blockchainAvailable } = this.state;
    const available = stAvailable && blockchainAvailable;

    if (
      !bidData ||
      stAvailable === "loading" ||
      blockchainAvailable === "loading"
    ) {
      return (
        <button className="eos-account auction`" key={item.account_name}>
          <span className="account-name">{item.account_name}</span>
          <span className="account-info available auction">
            <span>Cant load Auction Data</span>
          </span>
        </button>
      );
    }
    return (
      <Modal
        trigger={
          // eslint-disable-next-line react/button-has-type
          <button
            className={`eos-account ${available ? "available" : ""} auction`}
            key={item.account_name}
          >
            <span className="account-name">{item.account_name}</span>
            <span className="account-info available auction">
              <span>
                {available ? t("home.availableToBid") : t("home.unavailable")}
              </span>
            </span>
          </button>
        }
        size="small"
      >
        <Modal.Header content={item.account_name} />
        <Modal.Content>
          <p>
            {t("modals.bidnamebid.content1")} {t("modals.bidnamebid.content2")}{" "}
            {t("modals.bidnamebid.content3")}{" "}
            {t("modals.bidnamebid.pricinglink")}{" "}
            {t("modals.bidnamebid.content4")}
          </p>
          <BidnameBidForm
            initialValues={{
              bidAcctName: item.account_name,
              amount: addEos(
                bidData.getIn([0, "bid"], "0.0000 EOS"),
                "1.0000 EOS"
              )
            }}
          />

          <h1>Highest Bids</h1>
          {bidData && bidData.size !== 0 && (
            <p>
              {`${bidData.getIn([0, "name"])} ${bidData.getIn([0, "bid"])} `}{" "}
              <Countdown
                date={
                  new Date(
                    Date.now() +
                      (43200000 -
                        (Date.now() - bidData.getIn([0, "update_time"]) / 1000))
                  )
                }
              />
            </p>
          )}
          {bidData && bidData.size === 0 && (
            <p>There are currently no bids in this group.</p>
          )}
        </Modal.Content>
        <p />
      </Modal>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      return {};
    },
    dispatch => {
      return {
        addNameToCart: args => {
          dispatch(cartActions.addNameToCartRoutine(args));
        },
        openCartModal: args => {
          dispatch(modalActions.openCartModalRoutine(args));
        }
      };
    }
  )(withTranslation()(BidNameCard))
);
