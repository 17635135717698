/* eslint-disable react/button-has-type */
import React, { Component } from "react";
import {
  Image,
  Menu,
  Popup,
  Responsive,
  Sidebar,
  Segment,
  Icon,
  Container
} from "semantic-ui-react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { UserAgent } from "react-useragent";
import queryString from "query-string";
import Footer from "../footer/Footer";
import * as scatterActions from "../scatter/scatterActions";
import { ReactComponent as Logo } from "../assets/images/logo.svg";

class MobileContainer extends Component {
  state = {};

  componentDidMount() {
    const { connectToScatter } = this.props;
    connectToScatter();
  }

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const {
      history,
      children,
      identity,
      acctName,
      signOutOfScatter,
      signInToScatter,
      refid,
      widget
    } = this.props;
    const { sidebarOpened } = this.state;
    if (widget == "true") return <>{children}</>;
    return (
      <Responsive
        as={Sidebar.Pushable}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation="push"
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
          direction="right"
          className="mobile-sidebar"
        >
          <Menu.Item className="mobile-menu-item">
            <Link onClick={this.handleSidebarHide} to="/">
              Home
            </Link>
          </Menu.Item>
          <Menu.Item className="mobile-menu-item">
            <Link onClick={this.handleSidebarHide} to="/marketplace">
              Marketplace
            </Link>
          </Menu.Item>
          <Menu.Item className="mobile-menu-item">
            <Link onClick={this.handleSidebarHide} to="/pricing">
              Pricing
            </Link>
          </Menu.Item>
          <Menu.Item className="mobile-menu-item">
            <Link onClick={this.handleSidebarHide} to="/about">
              About
            </Link>
          </Menu.Item>
          <Menu.Item className="mobile-menu-item">
            <a href="mailto:support@eosnameservice.io">Support</a>
          </Menu.Item>
          <UserAgent>
            {({ ua }) => {
              return ua.mobile ? null : identity === null ? (
                <>
                  <Menu.Item
                    className="sign-in"
                    onClick={() => signInToScatter()}
                  >
                    Login
                  </Menu.Item>
                </>
              ) : (
                <>
                  <Menu.Item
                    className="sign-in"
                    onClick={() => signOutOfScatter()}
                  >
                    <Icon name="user" /> Hello There, {acctName}
                  </Menu.Item>
                </>
              );
            }}
          </UserAgent>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            textAlign="center"
            style={{ minHeight: "100vh", padding: "1em 0em" }}
            vertical
          >
            <Container>
              <Menu pointing secondary size="large">
                <Menu.Item>
                  <a href="https://eosnameservice.io">
                    <Logo className="ens-header-logo" />
                  </a>
                </Menu.Item>
                {!widget && (
                  <Menu.Item position="right" onClick={this.handleToggle}>
                    <Icon name="sidebar" />
                  </Menu.Item>
                )}
              </Menu>
            </Container>
            {children}
            <Footer />
          </Segment>
        </Sidebar.Pusher>
      </Responsive>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const isAuthenticated = state.wallet.get("isAuthenticated");
      const identity = !isAuthenticated ? null : isAuthenticated;
      const acctName = isAuthenticated;
      const q = queryString.parse(ownProps.location.search);
      const { refid, widget } = q;
      return {
        identity,
        acctName,
        refid,
        widget
      };
    },
    dispatch => {
      return {
        connectToScatter: args => {
          console.log("unmapped batch", args);
        },
        signInToScatter: args => {
          console.log("unmapped batch", args);
        },
        signOutOfScatter: args => {
          console.log("unmapped batch", args);
        }
      };
    }
  )(MobileContainer)
);
