import { createRoutine, bindRoutineToReduxForm } from "redux-saga-routines";

export const signInToScatterRoutine = createRoutine("scatter/SIGN_IN");

export const sellAccountRoutine = createRoutine("scatter/SELL_ACCOUNT");
export const sellAccountFormHandler = bindRoutineToReduxForm(
  sellAccountRoutine
);

export const startAuctionRoutine = createRoutine("scatter/START_AUCTION");
export const startAuctionFormHandler = bindRoutineToReduxForm(
  startAuctionRoutine
);

export const cancelAuctionRoutine = createRoutine("scatter/CANCEL_AUCTION");
export const cancelAuctionFormHandler = bindRoutineToReduxForm(
  cancelAuctionRoutine
);

export const buyAuctionAccountRoutine = createRoutine(
  "scatter/BUY_AUCTION_ACCOUNT"
);
export const buyAuctionAccountFormHandler = bindRoutineToReduxForm(
  buyAuctionAccountRoutine
);

export const bidAuctionRoutine = createRoutine("scatter/BID_AUCTION");
export const bidAuctionFormHandler = bindRoutineToReduxForm(bidAuctionRoutine);

export const bidnameBidRoutine = createRoutine("scatter/BIDNAME_BID");
export const bidnameBidFormHandler = bindRoutineToReduxForm(bidnameBidRoutine);

export const endAuctionRoutine = createRoutine("scatter/END_AUCTION");
export const endAuctionFormHandler = bindRoutineToReduxForm(endAuctionRoutine);

export const buyAccountRoutine = createRoutine("scatter/BUY_ACCOUNT");
export const buyAccountFormHandler = bindRoutineToReduxForm(buyAccountRoutine);

export const cancelSaleRoutine = createRoutine("scatter/CANCEL_SALE");
export const cancelSaleFormHandler = bindRoutineToReduxForm(cancelSaleRoutine);

export const updateSaleRoutine = createRoutine("scatter/UPDATE_SALE");
export const updateSaleFormHandler = bindRoutineToReduxForm(updateSaleRoutine);

export const createAccountsWithEosRoutine = createRoutine(
  "scatter/CREATE_ACCOUNTS_WITH_EOS"
);
export const createAccountsWithEosFormHandler = bindRoutineToReduxForm(
  createAccountsWithEosRoutine
);
