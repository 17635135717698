import React, { Component } from "react";
import {
  Table,
  Tab,
  Segment,
  Dimmer,
  Loader,
  Divider
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import SalesDataFilterForm from "./components/SalesDataFilterForm";

class Sales extends Component {
  render() {
    const {
      history,
      salesData,
      salesBySuffix,
      salesTotals,
      loadingSalesData
    } = this.props;

    const panes = [
      {
        menuItem: "Sales Details",
        render: () => <Tab.Pane>{salesDetail()}</Tab.Pane>
      },
      {
        menuItem: "Sales Totals",
        render: () => <Tab.Pane>{salesTotals && salesTotalsTab()}</Tab.Pane>
      },
      {
        menuItem: "Insights",
        render: () => <Tab.Pane>{salesInsights()}</Tab.Pane>
      },
      { menuItem: "...", render: () => <Tab.Pane>...</Tab.Pane> }
    ];

    const displaySalesRows = sales =>
      sales.map(sale => {
        const name = sale.get("name").includes("e--")
          ? sale.get("name").substring(0, 12)
          : sale.get("name");
        return (
          <Table.Row>
            <Table.Cell>
              {moment(sale.get("sale_date")).format("MM/DD/YYYY")}
            </Table.Cell>
            <Table.Cell>{name}</Table.Cell>
            <Table.Cell>{sale.get("sale_revenue").toFixed(4)}</Table.Cell>
            <Table.Cell>
              {(
                sale.get("sale_revenue") *
                sale.getIn(["HistoricalPrice", "price"])
              ).toFixed(2)}
            </Table.Cell>
            <Table.Cell>{sale.get("ram_expense") * 1}</Table.Cell>
            <Table.Cell>
              {(
                sale.get("ram_expense") *
                sale.getIn(["HistoricalPrice", "price"])
              ).toFixed(2)}
            </Table.Cell>
            <Table.Cell>
              {(sale.get("commission_expense", 0.0) * 1).toFixed(4)}
            </Table.Cell>
            <Table.Cell>
              {(
                sale.get("commission_expense", 0.0) *
                sale.getIn(["HistoricalPrice", "price"])
              ).toFixed(2)}
            </Table.Cell>
            <Table.Cell>
              {sale.get("commission_account") === ""
                ? "-"
                : sale.get("commission_account")}
            </Table.Cell>
            <Table.Cell>
              {(sale.get("referral_expense") * 1).toFixed(4)}
            </Table.Cell>
            <Table.Cell>
              {(
                sale.get("referral_expense") *
                sale.getIn(["HistoricalPrice", "price"])
              ).toFixed(2)}
            </Table.Cell>
            <Table.Cell>
              {sale.get("referral_account") === ""
                ? "-"
                : sale.get("referral_account")}
            </Table.Cell>
          </Table.Row>
        );
      });

    const salesDetail = () => (
      <>
        <h1>Sales</h1>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Account Name</Table.HeaderCell>
              <Table.HeaderCell>Revenue EOS</Table.HeaderCell>
              <Table.HeaderCell>Revenue USD</Table.HeaderCell>
              <Table.HeaderCell>RAM EOS</Table.HeaderCell>
              <Table.HeaderCell>RAM USD</Table.HeaderCell>
              <Table.HeaderCell>Commission EOS</Table.HeaderCell>
              <Table.HeaderCell>Commission USD</Table.HeaderCell>
              <Table.HeaderCell>Commission Acct</Table.HeaderCell>
              <Table.HeaderCell>Referral EOS</Table.HeaderCell>
              <Table.HeaderCell>Referral USD</Table.HeaderCell>
              <Table.HeaderCell>Referral Acct</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {salesData &&
              displaySalesRows(
                salesData.sortBy(s => s.get("sale_date")).reverse()
              )}
          </Table.Body>
        </Table>
      </>
    );

    const displaySalesBySuffixRows = suffixes =>
      suffixes.map(suffix => {
        const totalCommExp = suffix.get("total_commission_expense");
        const totalRefExp = suffix.get("total_referral_expense");
        return (
          <Table.Row>
            <Table.Cell>{suffix.get("suffix")}</Table.Cell>
            <Table.Cell>{suffix.get("names_sold")}</Table.Cell>
            <Table.Cell>{suffix.get("avg_revenue").toFixed(4)}</Table.Cell>
            <Table.Cell>{suffix.get("total_revenue").toFixed(4)}</Table.Cell>
            <Table.Cell>
              {suffix.get("total_ram_expense").toFixed(4)}
            </Table.Cell>
            <Table.Cell>
              {totalCommExp ? totalCommExp.toFixed(4) : 0.0}
            </Table.Cell>
            <Table.Cell>
              {totalRefExp ? totalRefExp.toFixed(4) : 0.0}
            </Table.Cell>
          </Table.Row>
        );
      });

    const salesInsights = () => (
      <>
        <h1>Sales by Suffix</h1>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Suffix</Table.HeaderCell>
              <Table.HeaderCell>Names Sold</Table.HeaderCell>
              <Table.HeaderCell>Avg Sale</Table.HeaderCell>
              <Table.HeaderCell>Total Sales</Table.HeaderCell>
              <Table.HeaderCell>Total Ram Expense</Table.HeaderCell>
              <Table.HeaderCell>Total Commission Expense</Table.HeaderCell>
              <Table.HeaderCell>Total Referral Expense</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {salesBySuffix &&
              displaySalesBySuffixRows(
                salesBySuffix.sortBy(s => s.get("names_sold")).reverse()
              )}
          </Table.Body>
        </Table>
      </>
    );

    const salesTotalsTab = () => (
      <>
        <h1>Totals</h1>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Names Sold</Table.HeaderCell>
              <Table.HeaderCell>Avg Sale</Table.HeaderCell>
              <Table.HeaderCell>Total Sales</Table.HeaderCell>
              <Table.HeaderCell>Total Ram Expense</Table.HeaderCell>
              <Table.HeaderCell>Total Commission Epense</Table.HeaderCell>
              <Table.HeaderCell>Total Referral Expense</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{salesTotals.get("names_sold")}</Table.Cell>
              <Table.Cell>
                {salesTotals.get("avg_revenue").toFixed(4)}
              </Table.Cell>
              <Table.Cell>
                {salesTotals.get("total_revenue").toFixed(4)}
              </Table.Cell>
              <Table.Cell>
                {salesTotals.get("total_ram_expense").toFixed(4)}
              </Table.Cell>
              <Table.Cell>
                {salesTotals.get("total_commission_expense")
                  ? salesTotals.get("total_commission_expense").toFixed(4)
                  : 0.0}
              </Table.Cell>
              <Table.Cell>
                {salesTotals.get("total_referral_expense")
                  ? salesTotals.get("total_referral_expense").toFixed(4)
                  : 0.0}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </>
    );

    return (
      <div className="status-page">
        <SalesDataFilterForm />
        <Divider hidden />
        {loadingSalesData && (
          <>
            <span />
            <Segment basic>
              <Dimmer active inverted>
                <Loader size="mini">Searching...</Loader>
              </Dimmer>
            </Segment>
          </>
        )}
        <Divider hidden />
        <Tab
          menu={{
            fluid: true,
            vertical: false,
            attached: true,
            widths: 4
          }}
          menuPosition="top"
          panes={panes}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const { role } = ownProps.match.params;
      return {
        role,
        loadingSalesData: state.admin.get("loadingSalesData"),
        salesData: state.admin.get("salesData"),
        salesBySuffix: state.admin.get("salesBySuffix"),
        salesTotals: state.admin.get("salesTotals")
          ? state.admin.get("salesTotals")
          : null
      };
    },
    dispatch => {
      return {};
    }
  )(Sales)
);
