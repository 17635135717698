import formActionSaga from "redux-form-saga";
import { fork, all } from "redux-saga/effects";
import { routinePromiseWatcherSaga } from "redux-saga-routines";
import { scatterWatches } from "../scatter/scatterSaga.js";
import { walletWatches } from "../wallet/walletSaga.js";
import { userWatches } from "../user/userSaga.js";
import { dfuseWatches } from "../dfuse/dfuseSaga";
import { pricingWatches } from "../pricing/pricingSaga";
import { adminWatches } from "../admin/adminSaga";

export default function* root() {
  const allWatches = [
    ...adminWatches,
    ...scatterWatches,
    ...walletWatches,
    ...userWatches,
    ...dfuseWatches,
    ...pricingWatches,
    routinePromiseWatcherSaga,
    formActionSaga
  ];

  yield all(allWatches.map(saga => fork(saga)));
}
