const isDev = process.env.NODE_ENV == "development";

const replaceAtIndex = (string, index, replacement) => {
  return (
    string.substr(0, index) +
    replacement +
    string.substr(index + replacement.length)
  );
};

const updateNextChar = (string, searchMaterial, replacement) => {
  const indexToChange = string.indexOf(searchMaterial) + searchMaterial.length;
  return replaceAtIndex(string, indexToChange, replacement);
};

export const overrideAutoPlay = (embedUrl, autoPlay) => {
  if (autoPlay == undefined) {
    autoPlay = !isDev;
  }

  const autoPlayString = "autoplay=";
  const containsAutoPlay = embedUrl.includes(autoPlayString);
  if (!containsAutoPlay) return embedUrl;

  return updateNextChar(embedUrl, autoPlayString, autoPlay ? "1" : "0");
};
