/* eslint-disable consistent-return */
/* eslint-disable react/button-has-type */
import React, { Component } from "react";
import { Button, Icon, Segment, Dimmer, Loader } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import { withTranslation } from "react-i18next";
import ecc from "eosjs-ecc";
import SearchForm from "../components/SearchForm";
import { getItemPrice } from "../pricing/pricingUtil";
import PurchaseModal from "./components/PurchaseModal";
import CreatingAccountModal from "./components/CreatingAccountModal";
import EosSuccessModal from "./components/EosSuccessModal";
import EosFailureModal from "./components/EosFailureModal";
import { setPageTitleRoutine } from "../common/commonActions";
import { getBidnameAuctionDataRoutine } from "../dfuse/dfuseActions";
import NameCard from "./components/PremiumNameCard";
import BidNameCard from "./components/BidNameCard";
import MarketplaceSaleCard from "./components/MarketplaceSaleCard";
import MarketplaceAuctionCard from "./components/MarketplaceAuctionCard";
import { overrideAutoPlay } from "../common/Helpers";
import { client } from "../App";
import partition from "lodash.partition";
import { JsonRpc, Api } from "eosjs";

const getRemainingCharactersAllowed = suggestedSubDomain =>
  12 - 1 - suggestedSubDomain.length;

const contract = "ens.xyz";

class Home extends Component {
  constructor(props) {
    super(props);

    this.onSearch = this.onSearch.bind(this);
    this.downloadPrices = this.downloadPrices.bind(this);
    this.checkAvailability = this.checkAvailability.bind(this);
  }

  state = {
    query: false,
    watch_video: false,
    fetch_result: [],
    accountPrices: [],
    loading: true,
    buyNowPrices: []
  };

  async downloadPrices() {
    this.setState({ loading: true });

    // const suffixes = await client.stateTable(contract, contract, "suffixes");

    // const accounts = suffixes.rows
    //   .map(row => row.json)
    //   .map(json => json.account);
    // console.log(accounts, "are the accounts");

    // const response = await client.stateTablesForScopes(
    //   contract,
    //   accounts,
    //   "prices"
    // );

    // const data = response.tables.map(row => ({
    //   prices: row.rows.map(x => x.json),
    //   account: row.scope
    // }));

    // const allMatching = data.every(accountSet =>
    //   accountSet.prices.every(price => price.suffix === accountSet.account)
    // );
    // console.assert(allMatching, "not all account names are matching!");

    // const accountPrices = data.map(accountAndPrices => ({
    //   ...accountAndPrices,
    //   prices: accountAndPrices.prices.map(price => ({
    //     amount: price.amount,
    //     length: price.length
    //   }))
    // }));

    const accountPrices = [...Array(10)].map((nothing, index) => {
      const suffix = Array.from(
        { length: 1 },
        () => Math.random().toString(36)[2]
      ).join("");
      console.log(suffix, "was suffix");
      return {
        prices: [...Array(8)].map((nothing, index) => ({
          amount: `${10 - index}.0000 EOS`,
          length: index + 1,
          suffix
        })),
        account: suffix
      };
    });

    console.log(accountPrices, "are going to get set");

    this.setState({ accountPrices, loading: false });
    if (this.state.query) {
      this.onSearch(this.state.query);
    }
  }

  onSearch(searchName) {
    const genericAccount = searchName.length === 12;
    if (genericAccount) {
      const buyNowPrices = [
        {
          account: searchName,
          cost: {
            amount: `0.5000 EOS`,
            length: 12,
            suffix: searchName
          },
          label: "Checking availability..."
        }
      ];
      this.setState({ buyNowPrices });
      this.checkAvailability(buyNowPrices.map(price => price.account));
      return;
    }

    const remainingCharactersLeft = getRemainingCharactersAllowed(searchName);

    const prices = this.state.accountPrices;

    const availablePrices = prices.filter(
      price => price.account.length <= remainingCharactersLeft
    );

    const buyNowPrices = availablePrices
      .map(price => ({
        account: `${searchName}.${price.account}`,
        cost: price.prices.find(p => p.length == searchName.length),
        label: "Checking availability..."
      }))
      .filter(price => price.cost);

    console.log(searchName, "came through");
    this.setState({ buyNowPrices });
    this.checkAvailability(buyNowPrices.map(price => price.account));
  }

  async checkAvailability(accountNames) {
    // account names is a string of eos accounts;
    // check to see if they actually exist or not

    return;
    console.log(accountNames, "was passed to check availablility");
    if (accountNames.length == 0) return;

    const response = await client.stateTablesForScopes(
      "eosio",
      accountNames,
      "userres"
    );

    const [existingAccounts, nonExisting] = partition(
      response.tables,
      table => table.rows.length > 0
    );

    console.log(response, "was response...", { existingAccounts, nonExisting });
    const buyNowPrices = this.state.buyNowPrices.map(price => {
      const existingAccount = response.tables.find(
        table =>
          table.rows.length > 0 &&
          table.scope.toLowerCase() == price.account.toLowerCase()
      );
      return {
        ...price,
        label: existingAccount ? "Taken." : "Available!"
      };
    });
    this.setState({ buyNowPrices });
  }

  // Did some research and making this async is fine especially since the state we are
  // setting here will never be set anywhere else.
  async componentDidMount() {
    this.downloadPrices();
    const { setPageTitle, getBidnameAuctionData, location } = this.props;
    const { search } = location;
    const query = queryString.parse(search);
    const privateKey = await ecc.randomKey();
    const publicKey = ecc.privateToPublic(privateKey);
    this.setState({
      privateKey,
      publicKey,
      query: query.name ? query.name : false,
      watch_video: false,
      fetch_result: []
    });
    if (query.name) {
      console.warn(
        "setting in component did mount",
        query.name,
        "as on search"
      );
      // this.onSearch(query.name);
    }
    getBidnameAuctionData();
    setPageTitle(
      "Create your EOS name | EOS Name Service | Create an EOS account now!"
    );
  }

  componentWillReceiveProps(nextProps) {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.location.search !== nextProps.location.search) {
      const query = queryString.parse(nextProps.location.search);
      if (query.name) {
        this.setState({
          query: query.name,
          watch_video: false,
          fetch_result: []
        });
        console.log(query.name, "is recorded query name");
        this.onSearch(query.name);
      } else {
        this.setState({
          query: false,
          watch_video: false,
          fetch_result: []
        });
      }
    }
  }

  showPurchase = (nameToPurchase, eosPrice, usdPrice) =>
    this.setState({
      nameToPurchase,
      eosPrice,
      usdPrice
    });

  fetchBidNames = query => {
    const bidnameSuffixes = [".com", ".io", ".eos", ".bank", ".games"];
    return bidnameSuffixes.map(name =>
      this.fetchBidName("" + query.substr(0, 12 - name.length) + name, name)
    );
  };

  fetchBidName = (name, suffix) => {
    return {
      account_name: name,
      available: "loading",
      bidname: true,
      bidnameSuffix: suffix
    };
  };

  // fetchData = query => {
  //   const { prices, marketplaceNames, widget } = this.props;

  //   const fetch_result = [];
  //   marketplaceNames.map(name => {
  //     if (
  //       !name.get("account4auction", "").includes(query) &&
  //       !name.get("account4sale", "").includes(query)
  //     )
  //       return null;
  //     const marketplaceNameForSale = {
  //       account_name: name.get("account4sale")
  //         ? name.get("account4sale")
  //         : name.get("account4auction"),
  //       isMarketplaceItem: true,
  //       isMarketplaceAuction: name.get("account4auction") !== undefined,
  //       eosPrice: name.get("saleprice"),
  //       eosBuyItNowPrice: name.get("buyitnowprice"),
  //       eosHighBid: name.get("highbid")
  //     };
  //     fetch_result.push(marketplaceNameForSale);
  //     return null;
  //   });

  //   if (prices) {
  //     const promises = prices
  //       .map(price => {
  //         if (query.length + price.get("suffix").length + 1 > 12) {
  //           const shortenedQuery = query.substr(
  //             0,
  //             11 - price.get("suffix").length
  //           );
  //           fetch_result.push({
  //             account_name: shortenedQuery + "." + price.get("suffix")
  //           });
  //           return null;
  //         }
  //         fetch_result.push({
  //           account_name: query + "." + price.get("suffix")
  //         });
  //         return null;
  //       })
  //       .toJS();

  //     if (query.length < 12) {
  //       const filler = "111111111111";
  //       const filledQuery = query + filler.slice(query.length);
  //       fetch_result.push({ account_name: filledQuery });
  //     }

  //     if (query.length === 12 && query.includes(".") === false) {
  //       fetch_result.push({ account_name: query });
  //     }

  //     if (widget !== "true") {
  //       promises.push(...this.fetchBidNames(query));
  //     }

  //     console.log("PROMISES", promises);

  //     // Filter promises so that accounts over 12 chars don't show up
  //     Promise.all(
  //       promises.filter(value => value !== undefined && value !== null)
  //     ).then(result => {
  //       fetch_result.push(...result);
  //       this.setState({ fetch_result });
  //     });
  //   } else {
  //     setTimeout(() => this.fetchData(query), 100);
  //   }
  // };

  render() {
    const {
      watch_video,
      query,
      // fetch_result,
      nameToPurchase,
      eosPrice,
      usdPrice
    } = this.state;
    const {
      prices,
      rate,
      referrer,
      cartItems,
      cartMultiEligible,
      widget,
      bidnameComData,
      bidnameBankData
    } = this.props;

    const client = {
      sandbox:
        "AbEU-zRgR789fXrUFSjJvLuyDxBn6zx5KS55wUC6O621fBju0RaxFIYq7cgoANoyqhQvhe8GWk9kiCgz",
      production: "Your-Production-Client-ID"
    };

    const generateNewPrivateKey = async () => {
      const newPrivateKey = await ecc.randomKey();
      const newPublicKey = ecc.privateToPublic(newPrivateKey);
      this.setState({
        privateKey: newPrivateKey,
        publicKey: newPublicKey
      });
    };

    const youtube_container = (
      <div className={`youtube-container ${watch_video ? "video-on" : ""}`}>
        <div className="ui active embed">
          <div className="embed">
            <iframe
              allowFullScreen
              frameBorder="0"
              height="100%"
              scrolling="no"
              src={overrideAutoPlay(
                "https://www.youtube.com/embed/gqS1sXf7DBU?autoplay=1&rel=0&enablejsapi=1&origin=https%3A%2F%2Fwww.eosnameservice.io&widgetid=1"
              )}
              title="Embedded content"
              width="100%"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              style={{ padding: 10 }}
            />
          </div>
        </div>
      </div>
    );
    const search_form = (
      <div
        className={`home-search ${query ? "active" : ""} ${
          watch_video ? "video-on" : ""
        }`}
      >
        <SearchForm
          query={query}
          referrer={referrer}
          onSearch={this.onSearch}
          widget={widget}
        />
      </div>
    );

    const item = {
      isMarketplaceItem: true,
      isMarketplaceAuction: false,
      bidname: false,
      bidnameSuffix: "few",
      account_name: "derp",
      eosPrice: "3.0000"
    };

    const buyNowPrices = this.state.buyNowPrices.map(x => ({
      ...item,
      eosPrice: x.cost.amount,
      account_name: x.account,
      label: x.label
    }));

    const fetch_result = Array.from({ length: 20 }).map(_ => item);
    const search_result = (
      <div className="search-result">
        {buyNowPrices.length === 0 && (
          <>
            <span />
            <Segment basic>
              <Dimmer active inverted>
                <Loader size="mini">Searching...</Loader>
              </Dimmer>
            </Segment>
          </>
        )}
        {buyNowPrices
          .map(item => {
            return <MarketplaceSaleCard item={item} />;
            if (item.isMarketplaceItem && item.isMarketplaceAuction) {
              return <MarketplaceAuctionCard item={item} />;
            }
            if (item.isMarketplaceItem) {
              return <MarketplaceSaleCard item={item} />;
            }

            if (item.bidname) {
              const bidData = [".com", ".eos", ".io"].includes(
                item.bidnameSuffix
              )
                ? bidnameComData
                : bidnameBankData;
              return <BidNameCard bidData={bidData} item={item} />;
            }
            const price = getItemPrice(
              item,
              prices,
              referrer,
              rate,
              cartItems,
              cartMultiEligible
            );
            return (
              <NameCard
                item={item}
                price={price}
                sortorder={
                  price && price.suffixInfo
                    ? price.suffixInfo.display_order
                    : 10000
                }
              />
            );
          })
          .sort((a, b) => {
            return a.props.sortorder - b.props.sortorder;
          })}
      </div>
    );
    return (
      <div className="home-page">
        <div className="video-search-container">
          {/* {widget !== "true" && !query && youtube_container} */}
          {/*!watch_video && */ search_form}
          {/* {widget !== "true" && !query && (
            <Button
              className="watch-video"
              color="google plus"
              onClick={() => this.setState({ watch_video: !watch_video })}
            >
              {watch_video ? "Search Name" : "Watch Video"}
            </Button>
          )} */}
          {query && search_result}
        </div>
        <PurchaseModal
          nameToPurchase={nameToPurchase}
          eosPrice={eosPrice}
          usdPrice={usdPrice}
          client={client}
        />
        <CreatingAccountModal />
        <EosSuccessModal />
        <EosFailureModal />
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const q = queryString.parse(ownProps.location.search);
      const marketplaceNames = state.dfuse.get("marketplaceNames");
      const { ref, widget } = q;
      const prices = state.pricing.get("prices");
      const rate = state.pricing.get("rate");
      const cartItems = state.cart.get("names");
      const acctKey = state.wallet.get("activeKey");
      console.warn(acctKey, "is the account key");

      return {
        cartItems,
        cartMultiEligible: state.cart.get("cartMultiEligible"),
        marketplaceNames,
        widget,
        referrer: ref ? ref : "none",
        prices,
        rate,
        bidnameComData: state.dfuse.get("bidnameComData"),
        bidnameBankData: state.dfuse.get("bidnameBankData")
      };
    },
    dispatch => {
      return {
        setPageTitle: args => {
          dispatch(setPageTitleRoutine(args));
        },
        getBidnameAuctionData: () => {
          dispatch(getBidnameAuctionDataRoutine());
        }
      };
    }
  )(withTranslation()(Home))
);
