import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { Button, Form, TextArea, Divider, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { bidnameBidFormHandler } from "../scatter/scatterActions";

class BidnameBidForm extends Component {
  render() {
    const { handleSubmit, scatterError, scatterSuccess, t } = this.props; // handleSubmit is provided by reduxForm
    return (
      <Form onSubmit={handleSubmit(bidnameBidFormHandler)}>
        <div className="modalFormFieldDiv">
          <label htmlFor="bidAcctName">
            {t("modals.bidnamebid.bidacctname")}
          </label>
          <Field
            component="input"
            type="text"
            name="bidAcctName"
            placeholder={t("modals.bidnamebid.bidacctname")}
            autoComplete="off"
          />
        </div>
        <div className="modalFormFieldDiv">
          <label htmlFor="amount">{t("marketplace.amount")}</label>
          <Field
            component="input"
            type="text"
            name="amount"
            placeholder={t("marketplace.amount")}
            autoComplete="off"
          />
        </div>
        <Divider hidden />
        <Button
          className="buy-account-submit-button"
          floated="right"
          type="submit"
        >
          {t("marketplace.bid.modal.submit")}
        </Button>
        <Divider hidden />
        {scatterSuccess && (
          <p>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`https://bloks.io/transaction/${scatterSuccess}`}
            >
              See transaction on blocks.io
            </a>
          </p>
        )}
        {scatterError && <p>{JSON.stringify(scatterError)}</p>}
      </Form>
    );
  }
}

export default withRouter(
  connect(state => {
    return {
      scatterSuccess: state.wallet.get("txSuccess"),
      scatterError: state.wallet.get("txError")
    };
  })(
    reduxForm({
      form: "BidnameBidForm"
    })(withTranslation()(BidnameBidForm))
  )
);
