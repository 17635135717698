/* eslint-disable consistent-return */
/* eslint-disable react/button-has-type */
import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import { withTranslation, Trans } from "react-i18next";
import { UserAgent } from "react-useragent";
import * as scatterActions from "../../scatter/scatterActions";
import * as modalActions from "../../modals/modalActions";
import { ReactComponent as ScatterLogo } from "../../assets/images/scatter.svg";
import { ReactComponent as EosLogo } from "../../assets/images/eos.svg";

class PurchaseModal extends Component {
  render() {
    const {
      cartItems,
      signInToScatter,
      acctName,
      createAccountsWithEos,
      totalEosCost,
      referrer,
      showPurchaseModal,
      closePurchaseModal,
      nameToPurchase,
      t
    } = this.props;
    return (
      <Modal size="tiny" open={showPurchaseModal} onClose={closePurchaseModal}>
        <Modal.Header>
          {acctName ? nameToPurchase : t("modals.purchase.header.loggedIn")}
        </Modal.Header>
        <Modal.Content>
          {acctName ? (
            <p>
              {t("modals.purchase.desc.loggedIn1")}
              {nameToPurchase}
              {t("modals.purchase.desc.loggedIn2")}
            </p>
          ) : (
            <UserAgent>
              {({ ua }) => {
                return ua.mobile ? null : (
                  <p>
                    {t("modals.purchase.desc.notLoggedIn1")}
                    {nameToPurchase}
                    {t("modals.purchase.desc.notLoggedIn2")}
                  </p>
                );
              }}
            </UserAgent>
          )}
          <div className="purchase-options-container">
            {!acctName && (
              <>
                <UserAgent>
                  {({ ua }) => {
                    return (
                      <div className="login-button-container purchase-options-container">
                        <button
                          className="login-button"
                          type="button"
                          onClick={signInToScatter}
                        >
                          <span>{t("modals.login.loginWithScatter")}</span>
                          <ScatterLogo />
                        </button>
                      </div>
                    );
                  }}
                </UserAgent>
              </>
            )}
            {acctName && (
              <div className="login-button-container purchase-options-container">
                <button
                  className="login-button"
                  type="button"
                  onClick={() =>
                    createAccountsWithEos({
                      cartItems,
                      amount: totalEosCost,
                      referrer
                    })
                  }
                >
                  <span>Buy with EOS</span>
                  <EosLogo />
                </button>
              </div>
            )}
            <span className="mobile-label">
              <br />
            </span>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const acctName = state.wallet.get("isAuthenticated");
      const q = queryString.parse(ownProps.location.search);
      const { ref } = q;
      const cartItems = state.cart.get("names");
      const totalEosCost = state.cart.get("totalEosCost");
      const totalUsdCost = state.cart.get("totalUsdCost");
      const prices = state.pricing.get("prices");
      const rate = state.pricing.get("rate");
      const showPurchaseModal = state.modal.get("showPurchaseModal");
      return {
        acctName,
        referrer: ref ? ref : "none",
        prices,
        rate,
        totalUsdCost,
        showPurchaseModal,
        cartItems,
        totalEosCost
      };
    },
    dispatch => {
      return {
        signInToScatter: args => {
          console.log("unmapped", args);
        },
        createAccountsWithEos: args => {
          dispatch(scatterActions.createAccountsWithEosRoutine(args));
        },
        closePurchaseModal: args => {
          dispatch(modalActions.closePurchaseModalRoutine());
        }
      };
    }
  )(withTranslation()(PurchaseModal))
);
