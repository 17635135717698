import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { Button, Responsive } from "semantic-ui-react";
import faker from "faker";
import { ReactComponent as SearchIcon } from "../assets/images/search-icon.svg";

class SearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: props.query
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ searchValue: nextProps.query || "" });
  }

  handleChange = event => {
    const searchValue = event.target.value
      .toLowerCase()
      .replace(/[^a-z1-5]/g, "");
    this.setState({
      searchValue
    });
    this.handleSubmit(searchValue);
  };

  formSubmit = event => {
    event.preventDefault();
    this.handleSubmit(this.state.searchValue);
  };

  handleSubmit = searchValue => {
    const { history, referrer, widget, onSearch } = this.props;

    onSearch(searchValue);
    console.log(
      searchValue,
      "is known as the search value",
      widget,
      "is the value of widget"
    );
    history.push(
      `/?${widget === "true" ? "widget=true&" : ""}name=${searchValue}${
        referrer ? `&ref=${referrer}` : ""
      }`
    );
  };

  render() {
    const { t, referrer, widget } = this.props;
    const { searchValue } = this.state;

    const renderTryLink = () => {
      const suggestions = [
        "boss",
        "currency",
        "crypto",
        "hodl",
        "hodl",
        "hire",
        "invest",
        "investment",
        "jobs",
        "shopping",
        "network",
        "pay",
        "profit",
        "retail",
        "retire",
        "recruit",
        "sales",
        "venture",
        "guitarist",
        "blessings",
        "dragon",
        "emperor",
        "fortune",
        "lion",
        "lunar",
        "rabbit",
        "red",
        "success",
        "tiger",
        "wealth",
        "winter",
        "papa",
        "agony",
        "crazy",
        "rapture",
        "fury",
        "fierce",
        "woke",
        "triggered",
        "dreamer",
        "grind",
        "writer",
        "alert",
        "attack",
        "auction",
        "battle",
        "buy",
        "cake",
        "claim",
        "exchange",
        "love",
        "mine",
        "miner",
        "play",
        "pump",
        "search",
        "shop",
        "sin",
        "trade",
        "blog"
      ];
      const name = faker.random.arrayElement(suggestions);
      return (
        <>
          <Link
            to={`/?${widget === "true" ? "widget=true&" : ""}name=${name}${
              referrer ? `&ref=${referrer}` : ""
            }`}
            onClick={() => this.handleSubmit(name)}
          >
            {name}
          </Link>
        </>
      );
    };

    return (
      <form className="search-form" onSubmit={this.formSubmit}>
        <div className="search-box">
          <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
            <input
              type="text"
              placeholder={t("home.searchplaceholderMobile")}
              title={t("home.searchtitle")}
              value={searchValue}
              maxLength="10"
              onChange={this.handleChange}
            />
          </Responsive>
          <Responsive minWidth={Responsive.onlyMobile.maxWidth}>
            <input
              type="text"
              placeholder={t("home.searchplaceholder")}
              title={t("home.searchtitle")}
              value={searchValue}
              maxLength="12"
              onChange={this.handleChange}
            />
          </Responsive>
          <div className="search-description">
            <span>{t("home.searchSuggestion")}&nbsp;</span>
            <span>
              {t("home.searchSuggestion2")}&nbsp;
              {renderTryLink()},&nbsp;
              {renderTryLink()},&nbsp;
              {renderTryLink()},&nbsp;
              {renderTryLink()},&nbsp;
              {renderTryLink()}
            </span>
          </div>
        </div>
        <Button className="icon">
          <SearchIcon className="search-icon" />
        </Button>
      </form>
    );
  }
}

export default withRouter(withTranslation()(SearchForm));
