import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Form } from "semantic-ui-react";
import { passwordResetFormHandler } from "../userActions";

class PasswordResetForm extends Component {
  render() {
    const { handleSubmit } = this.props; // handleSubmit is provided by reduxForm

    return (
      <Form onSubmit={handleSubmit(passwordResetFormHandler)}>
        <div className="modalFormFieldDiv">
          <label htmlFor="email">Email</label>
          <Field
            component="Input"
            type="text"
            name="email"
            placeholder="Email"
            autoComplete="off"
          />
        </div>
        <div className="spacer-div" />
        <div>
          <Button type="submit">Reset Password</Button>
        </div>
      </Form>
    );
  }
}

export default reduxForm({
  form: "PasswordResetForm" // a unique identifier for this form
})(PasswordResetForm);
