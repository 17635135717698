//import 'babel-polyfill';
import { put, takeLatest } from "redux-saga/effects";
import * as userActions from "./userActions";

import * as clientApi from "./userClientApi";

export const userWatches = [
  loginWatch,
  registerWatch,
  //passwordResetWatch,
  //passwordUpdateWatch,
  updateProfileWatch,
  requestProfileWatch,
  getUserListWatch,
  approveUserWatch,
  suspendUserWatch
];

function* loginWatch() {
  yield takeLatest(userActions.loginRoutine.TRIGGER, doLogin);
}
/*
function* passwordUpdateWatch() {
  yield takeLatest(userActions.UPDATE_PASSWORD, doUpdatePassword);
}

function* passwordResetWatch() {
  yield takeLatest(userActions.PASSWORD_RESET, doResetPassword)
}
*/
function* registerWatch() {
  yield takeLatest(userActions.registerRoutine.TRIGGER, doRegistrationStep);
}

function* updateProfileWatch() {
  yield takeLatest(userActions.editProfileRoutine.TRIGGER, doEditProfile);
}

function* approveUserWatch() {
  yield takeLatest(userActions.approveUserRoutine.TRIGGER, approveUser);
}

function* suspendUserWatch() {
  yield takeLatest(userActions.suspendUserRoutine.TRIGGER, suspendUser);
}

function* requestProfileWatch() {
  yield takeLatest(userActions.requestProfileRoutine.TRIGGER, requestProfile);
}

function* getUserListWatch() {
  yield takeLatest(userActions.getUserListRoutine.TRIGGER, getUserList);
}

function* doLogin(payload) {
  try {
    const serverResponse = yield clientApi.clientApiLogin(payload);
    if (serverResponse.data.token) {
      yield put(userActions.loginRoutine.success(serverResponse.data));
      if (serverResponse.data.role === "admin") {
        payload.payload.values.history.push("/");
      }
    } else {
      yield put(userActions.loginRoutine.failure(serverResponse));
    }
  } catch (error) {
    yield put(userActions.loginRoutine.failure(error.response.data.error));
  }
}

function* doRegistrationStep(payload) {
  try {
    const serverResponse = yield clientApi.clientApiRegister(payload);
    if (serverResponse.error) {
      yield put(userActions.registerRoutine.failure(serverResponse));
    } else {
      payload.payload.props.history.push("/registration_successful");
    }
  } catch (e) {
    console.error("e", e);
    yield put(userActions.registerRoutine.failure(e.response.data.error));
  }
}

/*
function* doResetPassword(payload) {
  const serverResponse = yield clientApiResetPassword(payload);
  if (serverResponse.error) {
    yield put(userActions.passwordResetFailed(serverResponse));
  } else {
    yield put(userActions.passwordResetSucceeded(serverResponse));
  }
}

function* doUpdatePassword(payload) {
  const serverResponse = yield clientApiUpdatePassword(payload);
  if (serverResponse.error) {
    yield put(userActions.updatePasswordFailed(serverResponse));
  } else {
    yield put(userActions.updatePasswordSucceeded(serverResponse));
  }
}*/

function* doEditProfile(payload) {
  try {
    const serverResponse = yield clientApi.clientApiEditProfile(payload);
    if (serverResponse.data.profile) {
      yield put(
        userActions.editProfileRoutine.success(serverResponse.data.profile)
      );
      payload.payload.values.history.push(
        `/user_profile/${payload.payload.values.username}`
      );
    } else {
      yield put(userActions.editProfileRoutine.failure(serverResponse));
    }
  } catch (error) {
    yield put(
      userActions.editProfileRoutine.failure(error.response.data.error)
    );
  }
}

function* requestProfile(payload) {
  try {
    const serverResponse = yield clientApi.clientApiGetProfile(payload);
    if (serverResponse.data.profile) {
      yield put(
        userActions.requestProfileRoutine.success(serverResponse.data.profile)
      );
    } else {
      yield put(userActions.requestProfileRoutine.failure(serverResponse));
    }
  } catch (error) {
    yield put(
      userActions.requestProfileRoutine.failure(error.response.data.error)
    );
  }
}

function* getUserList(payload) {
  try {
    const serverResponse = yield clientApi.getUserList(payload);
    if (serverResponse.data.users) {
      yield put(
        userActions.getUserListRoutine.success(serverResponse.data.users)
      );
    } else {
      yield put(userActions.getUserListRoutine.failure(serverResponse));
    }
  } catch (error) {
    yield put(
      userActions.getUserListRoutine.failure(error.response.data.error)
    );
  }
}

function* approveUser(payload) {
  try {
    const serverResponse = yield clientApi.approveUser(payload);
    if (serverResponse.data.users) {
      yield put(userActions.approveUserRoutine.success());
      yield put(
        userActions.getUserListRoutine.success(serverResponse.data.users)
      );
    } else {
      yield put(userActions.approveUserRoutine.failure(serverResponse));
    }
  } catch (error) {
    yield put(
      userActions.approveUserRoutine.failure(error.response.data.error)
    );
  }
}

function* suspendUser(payload) {
  try {
    const serverResponse = yield clientApi.suspendUser(payload);
    if (serverResponse.data.users) {
      yield put(userActions.suspendUserRoutine.success());
      yield put(
        userActions.getUserListRoutine.success(serverResponse.data.users)
      );
    } else {
      yield put(userActions.suspendUserRoutine.failure(serverResponse));
    }
  } catch (error) {
    yield put(
      userActions.suspendUserRoutine.failure(error.response.data.error)
    );
  }
}
