import React, { Component } from "react";
import { Modal, Divider, Button, Input } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import * as modalActions from "../modalActions";

class ReferralModal extends Component {
  copyTextToClipboard = text => {
    if (!navigator.clipboard) {
      return;
    }
    navigator.clipboard.writeText(text).then(
      () => {},
      err => {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  render() {
    const {
      showReferralModal,
      closeReferralModal,
      acctName,
      openLoginModal,
      t
    } = this.props;

    return (
      <Modal size="tiny" open={showReferralModal} onClose={closeReferralModal}>
        <Modal.Header>{t("referral.inviteTitle")}</Modal.Header>
        <Modal.Content>
          <div className="referral-modal-content">
            <Divider hidden />
            <p>{t("referral.inviteDescr")}</p>
            <Divider hidden />
            <label>{t("referral.inviteAccountName")}</label>
            <input type="text" value={acctName} readOnly />
            {!acctName && (
              <p>
                <a
                  href="#"
                  onClick={() => {
                    closeReferralModal();
                    openLoginModal();
                  }}
                >
                  {t("navigation.header.login")}
                </a>
                {t("referral.loginToFillAutomatically")}
              </p>
            )}
            <Divider hidden />
            <label>{t("referral.referralLink")}</label>
            <Input
              className="copy-btn"
              readOnly
              onClick={() =>
                this.copyTextToClipboard(
                  `https://www.eosnameservice.io/?ref=${acctName}`
                )
              }
            >
              {`https://www.eosnameservice.io/?ref=${acctName}`}
            </Input>
            <Button
              className="copy-btn"
              action="COPY"
              onClick={() =>
                this.copyTextToClipboard(
                  `https://www.eosnameservice.io/?ref=${acctName}`
                )
              }
            >
              {t("referral.copy")}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const showReferralModal = state.modal.get("showReferralModal");
      const acctName = state.wallet.get("isAuthenticated");
      return {
        showReferralModal,
        acctName
      };
    },
    dispatch => {
      return {
        closeReferralModal: args => {
          dispatch(modalActions.closeReferralModalRoutine());
        },
        openLoginModal: args => {
          dispatch(modalActions.openLoginModalRoutine());
        }
      };
    }
  )(withTranslation()(ReferralModal))
);
