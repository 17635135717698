import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import * as cartActions from "../../cart/cartActions";
import * as modalActions from "../../modals/modalActions";
import { ReactComponent as EosLogo } from "../../assets/images/eos.svg";
import BuyAccountForm from "../../marketplace/BuyAccountForm";

class MarketplaceSaleCard extends Component {
  render() {
    const {
      scatterError,
      scatterSuccess,
      item,
      t,
      acctKey,
      label
    } = this.props;
    return (
      <Modal
        trigger={
          // eslint-disable-next-line react/button-has-type
          <button className="eos-account available" key={item.account_name}>
            <span className="account-name">{item.account_name}</span>
            <span className="account-info available">
              <span className="info">
                <EosLogo />
                <span>{item.eosPrice}</span>
              </span>
              <span>{item.label}</span>
            </span>
          </button>
        }
        size="small"
      >
        <Modal.Header content={t("marketplace.buy.modal.header")} />
        <Modal.Content>
          <p>
            {t("marketplace.buy.modal.content1")}
            <a href={`https:bloks.io/account/${item.account_name}`}>
              {item.account_name}
            </a>
            {t("marketplace.buy.modal.content2")}
            <a href="https://eosnameservice.io/terms-conditions">
              {t("navigation.header.terms")}
            </a>
            .
          </p>
          <BuyAccountForm
            initialValues={{
              buyAcctName: item.account_name,
              amount: item.eosPrice,
              key: acctKey
            }}
          />
        </Modal.Content>
        <p />
        {scatterSuccess && (
          <p>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`https://bloks.io/transaction/${scatterSuccess}`}
            >
              See transaction on blocks.io
            </a>
          </p>
        )}
        {scatterError && <p>{JSON.stringify(scatterError)}</p>}
      </Modal>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      // todo
      const acctKey = state.wallet.get("activeKey");
      return {
        acctKey
      };
    },
    dispatch => {
      return {
        addNameToCart: args => {
          dispatch(cartActions.addNameToCartRoutine(args));
        },
        openCartModal: args => {
          dispatch(modalActions.openCartModalRoutine(args));
        }
      };
    }
  )(withTranslation()(MarketplaceSaleCard))
);
