/* eslint-disable consistent-return */
/* eslint-disable react/button-has-type */
import React, { Component } from "react";
import { Modal, Table, Icon, Button, Label } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import * as modalActions from "../../modals/modalActions";
import * as cartActions from "../cartActions";
import * as pricingUtils from "../../pricing/pricingUtil";

class CartModal extends Component {
  render() {
    const {
      cartItems,
      t,
      showCartModal,
      closeCartModal,
      removeNameFromCart,
      emptyCart,
      totalEosCost,
      totalUsdCost,
      openPurchaseModal,
      totalRegEosCost,
      totalRegUsdCost
    } = this.props;
    const renderShoppingCartRows = rows =>
      rows.map((row, k) => {
        const rowjs = row.toJS();
        const eosSavings = (rowjs.regEosPrice - rowjs.eosPrice).toFixed(4);
        const usdSavings = (rowjs.regUsdPrice - rowjs.usdPrice).toFixed(2);
        return (
          <Table.Row key={rowjs.account_name}>
            <Table.Cell>
              <span className="mobile-label">
                Name: <br />
              </span>
              {k !== 0 && eosSavings !== "0.0000" ? (
                <Label color="green" ribbon>
                  {rowjs.account_name}
                </Label>
              ) : (
                rowjs.account_name
              )}
            </Table.Cell>
            <Table.Cell>
              <span className="mobile-label">EOS: </span>
              {rowjs.eosPrice}
            </Table.Cell>
            <Table.Cell>
              <span className="mobile-label">USD: </span>
              {rowjs.usdPrice}
            </Table.Cell>
            <Table.Cell>
              <span className="mobile-label">EOS Savings: </span>
              {eosSavings !== "0.0000" ? (
                <Label color="green" tag>
                  Save {eosSavings} EOS
                </Label>
              ) : (
                "No Discount for this Name"
              )}
            </Table.Cell>
            <Table.Cell>
              <span className="mobile-label">USD Savings: </span>
              {usdSavings !== "0.00" ? (
                <Label color="green" tag>
                  Save ${usdSavings}
                </Label>
              ) : (
                "No Discount for this Name"
              )}
            </Table.Cell>
            <Table.Cell>
              <span className="mobile-label">Remove: </span>
              <Icon
                className="buy-trigger"
                name="cancel"
                onClick={() =>
                  k === 0 ? emptyCart() : removeNameFromCart(rowjs)
                }
              />
            </Table.Cell>
          </Table.Row>
        );
      });

    const totalEosSavings = (totalRegEosCost - totalEosCost).toFixed(4);
    const totalUsdSavings = (totalRegUsdCost - totalUsdCost).toFixed(2);

    return (
      <Modal size="large" open={showCartModal} onClose={closeCartModal}>
        <Modal.Header>Shopping Cart</Modal.Header>
        <Modal.Content>
          {cartItems &&
            cartItems.size > 0 &&
            "You are now eligble to add additional names at a discount.  If you remove the original name from the cart all discounted items will also be removed."}
          <Table>
            <Table.Header className="cart-header">
              <Table.Row>
                <Table.HeaderCell>{t("marketplace.name")}</Table.HeaderCell>
                <Table.HeaderCell>EOS</Table.HeaderCell>
                <Table.HeaderCell>USD</Table.HeaderCell>
                <Table.HeaderCell>EOS Savings</Table.HeaderCell>
                <Table.HeaderCell>USD Savings</Table.HeaderCell>
                <Table.HeaderCell>Remove from Cart</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {cartItems && renderShoppingCartRows(cartItems.toList())}
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell>Totals</Table.HeaderCell>
                <Table.HeaderCell>
                  <span className="mobile-label">Total EOS: </span>
                  {totalEosCost}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <span className="mobile-label">Total USD: </span>
                  {totalUsdCost}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <span className="mobile-label">EOS Savings: </span>
                  {totalEosSavings !== "0.0000" ? (
                    <Label color="green" tag>
                      Save {totalEosSavings} EOS
                    </Label>
                  ) : (
                    ""
                  )}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <span className="mobile-label">USD Savings: </span>
                  {totalUsdSavings !== "0.00" ? (
                    <Label color="green" tag>
                      Save ${totalUsdSavings}
                    </Label>
                  ) : (
                    ""
                  )}
                </Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
          <Button
            className="mobile-cart-button"
            disabled={!(cartItems && cartItems.size > 0)}
            onClick={emptyCart}
          >
            Empty Cart
          </Button>
          <span className="mobile-label">
            <br />
          </span>
          <Button className="mobile-cart-button" onClick={closeCartModal}>
            Continue Shopping
          </Button>
          <Button
            className="mobile-cart-button"
            disabled={!(cartItems && cartItems.size > 0)}
            onClick={openPurchaseModal}
          >
            Go to Checkout
          </Button>
        </Modal.Content>
      </Modal>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const cartItems = state.cart.get("names");
      const totalEosCost = state.cart.get("totalEosCost");
      const totalUsdCost = state.cart.get("totalUsdCost");
      const totalRegEosCost = state.cart.get("totalRegEosCost");
      const totalRegUsdCost = state.cart.get("totalRegUsdCost");
      const showCartModal = state.modal.get("showCartModal");
      return {
        cartItems,
        showCartModal,
        totalEosCost,
        totalUsdCost,
        totalRegEosCost,
        totalRegUsdCost
      };
    },
    dispatch => {
      return {
        closeCartModal: args => {
          dispatch(modalActions.closeCartModalRoutine());
        },
        removeNameFromCart: args => {
          dispatch(cartActions.removeNameFromCartRoutine(args));
        },
        emptyCart: args => {
          dispatch(cartActions.emptyCartRoutine());
        },
        openPurchaseModal: args => {
          dispatch(modalActions.openPurchaseModalRoutine());
        }
      };
    }
  )(withTranslation()(CartModal))
);
