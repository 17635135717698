import { fromJS } from "immutable";
import * as scatterActions from "./scatterActions";

const defaultState = fromJS({
  //connected: false,
  //identity: null,
  connection: null,
  account: null,
  scatterSuccessMessage: null,
  scatterErrorMessage: null,
  waitingForScatter: false
});

export default function reducer(state = defaultState, action) {
  const { payload } = action;

  switch (action.type) {
    case scatterActions.signInToScatterRoutine.SUCCESS: {
      const newState = state.set("account", fromJS(payload));
      return newState;
    }

    case scatterActions.sellAccountRoutine.TRIGGER: {
      return state
        .set("scatterSuccessMessage", null)
        .set("scatterErrorMessage", null);
    }

    case scatterActions.sellAccountRoutine.SUCCESS: {
      return state.set("scatterSuccessMessage", fromJS(payload));
    }

    case scatterActions.sellAccountRoutine.FAILURE: {
      return state.set("scatterErrorMessage", fromJS(payload));
    }

    case scatterActions.startAuctionRoutine.TRIGGER: {
      return state
        .set("scatterSuccessMessage", null)
        .set("scatterErrorMessage", null);
    }

    case scatterActions.startAuctionRoutine.SUCCESS: {
      return state.set("scatterSuccessMessage", fromJS(payload));
    }

    case scatterActions.startAuctionRoutine.FAILURE: {
      return state.set("scatterErrorMessage", fromJS(payload));
    }

    case scatterActions.bidAuctionRoutine.TRIGGER: {
      return state
        .set("waitingForScatter", true)
        .set("scatterSuccessMessage", null)
        .set("scatterErrorMessage", null);
    }

    case scatterActions.bidAuctionRoutine.SUCCESS: {
      return state
        .set("waitingForScatter", false)
        .set("scatterSuccessMessage", fromJS(payload));
    }

    case scatterActions.bidAuctionRoutine.FAILURE: {
      return state
        .set("waitingForScatter", false)
        .set("scatterErrorMessage", fromJS(payload))
        .set("scatterSuccessMessage", null);
    }

    case scatterActions.bidnameBidRoutine.TRIGGER: {
      return state
        .set("waitingForScatter", true)
        .set("scatterSuccessMessage", null)
        .set("scatterErrorMessage", null);
    }

    case scatterActions.bidnameBidRoutine.SUCCESS: {
      return state
        .set("waitingForScatter", false)
        .set("scatterSuccessMessage", fromJS(payload));
    }

    case scatterActions.bidnameBidRoutine.FAILURE: {
      return state
        .set("waitingForScatter", false)
        .set("scatterErrorMessage", fromJS(payload))
        .set("scatterSuccessMessage", null);
    }

    case scatterActions.buyAuctionAccountRoutine.TRIGGER: {
      return state
        .set("waitingForScatter", true)
        .set("scatterSuccessMessage", null)
        .set("scatterErrorMessage", null);
    }

    case scatterActions.buyAuctionAccountRoutine.SUCCESS: {
      return state
        .set("waitingForScatter", false)
        .set("scatterSuccessMessage", fromJS(payload));
    }

    case scatterActions.buyAuctionAccountRoutine.FAILURE: {
      return state
        .set("waitingForScatter", false)
        .set("scatterErrorMessage", fromJS(payload))
        .set("scatterSuccessMessage", null);
    }

    case scatterActions.buyAccountRoutine.TRIGGER: {
      return state
        .set("scatterSuccessMessage", null)
        .set("scatterErrorMessage", null);
    }

    case scatterActions.buyAccountRoutine.SUCCESS: {
      return state.set("scatterSuccessMessage", fromJS(payload));
    }

    case scatterActions.buyAccountRoutine.FAILURE: {
      return state.set("scatterErrorMessage", fromJS(payload));
    }

    case scatterActions.updateSaleRoutine.TRIGGER: {
      return state
        .set("scatterSuccessMessage", null)
        .set("scatterErrorMessage", null);
    }

    case scatterActions.updateSaleRoutine.SUCCESS: {
      return state.set("scatterSuccessMessage", fromJS(payload));
    }

    case scatterActions.updateSaleRoutine.FAILURE: {
      return state.set("scatterErrorMessage", fromJS(payload));
    }

    case scatterActions.cancelSaleRoutine.TRIGGER: {
      return state
        .set("scatterSuccessMessage", null)
        .set("scatterErrorMessage", null);
    }

    case scatterActions.cancelSaleRoutine.SUCCESS: {
      return state.set("scatterSuccessMessage", fromJS(payload));
    }

    case scatterActions.cancelSaleRoutine.FAILURE: {
      return state.set("scatterErrorMessage", fromJS(payload));
    }

    default:
      return state;
  }
}
