/* eslint-disable consistent-return */
/* eslint-disable react/button-has-type */
import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import * as modalActions from "../../modals/modalActions";

class EosFailureModal extends Component {
  render() {
    const {
      showEosAccountCreationFailureModal,
      closeEosFailureModal,
      scatterErrorMessage
    } = this.props;

    return (
      <Modal
        size="small"
        open={showEosAccountCreationFailureModal}
        onClose={closeEosFailureModal}
      >
        <Modal.Header>Something went wrong</Modal.Header>
        <Modal.Content>{JSON.stringify(scatterErrorMessage)}</Modal.Content>
      </Modal>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const showEosAccountCreationFailureModal = state.modal.get(
        "showEosAccountCreationFailureModal"
      );
      const scatterErrorMessage = state.wallet.get("txError");
      return {
        showEosAccountCreationFailureModal,
        scatterErrorMessage
      };
    },
    dispatch => {
      return {
        closeEosFailureModal: args => {
          dispatch(modalActions.closeEosFailureModalRoutine());
        }
      };
    }
  )(withTranslation()(EosFailureModal))
);
