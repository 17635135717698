import { fromJS } from "immutable";
import * as modalActions from "./modalActions";

const defaultState = fromJS({
  showPurchaseModal: false,
  showCreatingAccountModal: false,
  showEosAccountCreationSuccessModal: false,
  showEosAccountCreationFailureModal: false,
  showWalletDnsModal: false,
  showLoginModal: false,
  showReferralModal: false,
  showCartModal: false,
  showSellAccountModal: false,
  showAuctionAccountModal: false,
  showPlaceBidModal: false,
  showBuyItNowModal: false,
  showCancelAuctionModal: false
});

export default function reducer(state = defaultState, action) {
  const { payload } = action;

  switch (action.type) {
    case modalActions.openPurchaseModalRoutine.TRIGGER: {
      return state.set("showPurchaseModal", true);
    }

    case modalActions.closePurchaseModalRoutine.TRIGGER: {
      return state.set("showPurchaseModal", false);
    }

    case modalActions.openSellAccountModalRoutine.TRIGGER: {
      return state.set("showSellAccountModal", true);
    }

    case modalActions.closeSellAccountModalRoutine.TRIGGER: {
      return state.set("showSellAccountModal", false);
    }

    case modalActions.openWalletDnsModalRoutine.TRIGGER: {
      return state.set("showWalletDnsModal", true);
    }

    case modalActions.closeWalletDnsModalRoutine.TRIGGER: {
      return state.set("showWalletDnsModal", false);
    }

    case modalActions.openAuctionAccountModalRoutine.TRIGGER: {
      return state.set("showAuctionAccountModal", true);
    }

    case modalActions.closeAuctionAccountModalRoutine.TRIGGER: {
      return state.set("showAuctionAccountModal", false);
    }

    case modalActions.openPlaceBidModalRoutine.TRIGGER: {
      return state.set("showPlaceBidModal", true);
    }

    case modalActions.closePlaceBidModalRoutine.TRIGGER: {
      return state.set("showPlaceBidModal", false);
    }

    case modalActions.openBuyItNowModalRoutine.TRIGGER: {
      return state
        .set("showBuyItNowModal", true)
        .set("account4auction", payload.account4auction)
        .set("buyItNowPrice", payload.buyItNowPrice);
    }

    case modalActions.closeBuyItNowModalRoutine.TRIGGER: {
      return state.set("showBuyItNowModal", false);
    }

    case modalActions.openCartModalRoutine.TRIGGER: {
      return state.set("showCartModal", true);
    }

    case modalActions.closeCartModalRoutine.TRIGGER: {
      return state.set("showCartModal", false);
    }

    case modalActions.openCreatingAccountModalRoutine.TRIGGER: {
      return state.set("showCreatingAccountModal", true);
    }

    case modalActions.closeCreatingAccountModalRoutine.TRIGGER: {
      return state.set("showCreatingAccountModal", false);
    }

    case modalActions.openEosSuccessModalRoutine.TRIGGER: {
      return state
        .set("showEosAccountCreationSuccessModal", true)
        .set("showPurchaseModal", false);
    }

    case modalActions.closeEosSuccessModalRoutine.TRIGGER: {
      return state.set("showEosAccountCreationSuccessModal", false);
    }

    case modalActions.openEosFailureModalRoutine.TRIGGER: {
      return state
        .set("showEosAccountCreationFailureModal", true)
        .set("showPurchaseModal", false);
    }

    case modalActions.closeEosFailureModalRoutine.TRIGGER: {
      return state.set("showEosAccountCreationFailureModal", false);
    }

    case modalActions.openLoginModalRoutine.TRIGGER: {
      return state.set("showLoginModal", true);
    }

    case modalActions.closeLoginModalRoutine.TRIGGER: {
      return state.set("showLoginModal", false);
    }

    case modalActions.openReferralModalRoutine.TRIGGER: {
      return state.set("showReferralModal", true);
    }

    case modalActions.closeReferralModalRoutine.TRIGGER: {
      return state.set("showReferralModal", false);
    }

    default:
      return state;
  }
}
