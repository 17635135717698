import React, { Component } from "react";
import { withRouter, Link, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import CookieConsent, { Cookies } from "react-cookie-consent";
import queryString from "query-string";
import * as pricingActions from "../pricing/pricingActions";
import DesktopContainer from "./DesktopContainer";
import MobileContainer from "./MobileContainer";
import Home from "../home/Home";
// import Marketplace from "../marketplace/Marketplace";
import About from "../about/About";
import Pricing from "../pricing/Pricing";
import Register from "../user/RegisterPage";
import Login from "../user/login/Login";
import Status from "../partner/Status";
import Partners from "../admin/Partners";
import Sales from "../admin/Sales";
import LoginModal from "../modals/components/LoginModal";
import ReferralModal from "../modals/components/ReferralModal";
import CartModal from "../cart/components/CartModal";
import * as dfuseActions from "../dfuse/dfuseActions";
import Title from "../Title";
import Promotions from "../admin/Promotions";
import AuctionAccountModal from "../modals/components/AuctionAccountModal";
import Privacy from "../static/Privacy";
import Terms from "../static/Terms";
import WalletDnsModal from "../modals/components/WalletDnsModal";
import RegistrationSuccessful from "../user/login/RegistrationSuccessful";

class ResponsiveContainer extends Component {
  componentWillMount() {
    const { getMarketPlaceNames, fetchPrices, widget } = this.props;
    if (!widget) getMarketPlaceNames();
    fetchPrices(widget);
  }

  render() {
    const children = (
      <Switch>
        <Route exact path="/" component={Home} key="home" />
        <Route exact path="/about" component={About} />
        <Route exact path="/pricing" component={Pricing} />
        {/* <Route exact path="/marketplace" component={Marketplace} /> */}
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/terms" component={Terms} />

        <Route exact path="/partner/register" component={Register} />
        <Route exact path="/partner/login" component={Login} />
        <Route exact path="/partner/status" component={Status} />
        <Route
          exact
          path="/registration_successful"
          component={RegistrationSuccessful}
        />

        <Route exact path="/admin/partners" component={Partners} />
        <Route exact path="/admin/sales" component={Sales} />
        <Route exact path="/admin/login" component={Login} />
        <Route exact path="/admin/promotions" component={Promotions} />
      </Switch>
    );

    return (
      <div>
        <Title />
        <DesktopContainer>{children}</DesktopContainer>
        <MobileContainer>{children}</MobileContainer>
        <LoginModal />
        <ReferralModal />
        <CartModal />
        <AuctionAccountModal />
        <WalletDnsModal />
        <CookieConsent>
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const q = queryString.parse(ownProps.location.search);
      const { ref, widget } = q;
      return {
        current_user: state.user.get("user"),
        ref,
        widget
      };
    },
    dispatch => {
      return {
        getMarketPlaceNames: args => {
          dispatch(dfuseActions.getMarketPlaceNamesRoutine(args));
        },
        fetchPrices: widget =>
          dispatch(pricingActions.fetchPricesRoutine({ widget }))
      };
    }
  )(ResponsiveContainer)
);
