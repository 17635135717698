const logger = store => next => action => {
  console.group(action.type);
  console.info("dispatching", action);
  const result = next(action);
  const newState = store.getState();
  console.log("next state", {
    pricing: newState.pricing.toJS(),
    user: newState.user.toJS(),
    dfuse: newState.dfuse.toJS(),
    form: newState.form,
    modal: newState.modal.toJS(),
    cart: newState.cart.toJS(),
    common: newState.common.toJS()
  });
  console.groupEnd();
  return result;
};

export default logger;
