import React, { Component } from "react";
import {
  Grid,
  Image,
  Header,
  Divider,
  Button,
  Responsive
} from "semantic-ui-react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import RegisterForm from "./login/RegisterForm";

class RegisterPage extends Component {
  render() {
    const { history } = this.props;

    return (
      <div className="login-page">
        <RegisterForm initialValues={{ history }} />
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const { role } = ownProps.match.params;
      return {
        role,
        loggedIn: state.user.get("loggedIn"),
        loggingIn: state.user.get("loggingIn"),
        loginError: JSON.stringify(state.user.get("loginError")),
        user: state.user.get("user")
      };
    },
    dispatch => {
      return {};
    }
  )(RegisterPage)
);
