import { reducer as formReducer } from "redux-form";
import { combineReducers } from "redux";
import scatterReducer from "../scatter/scatterReducer";
import walletReducer from "../wallet/walletReducer";
import userReducer from "../user/userReducer";
import dfuseReducer from "../dfuse/dfuseReducer";
import pricingReducer from "../pricing/pricingReducer";
import modalReducer from "../modals/modalReducer";
import cartReducer from "../cart/cartReducer";
import commonReducer from "../common/commonReducer";
import adminReducer from "../admin/adminReducer";

export default combineReducers({
  admin: adminReducer,
  scatter: scatterReducer,
  wallet: walletReducer,
  user: userReducer,
  dfuse: dfuseReducer,
  form: formReducer,
  pricing: pricingReducer,
  modal: modalReducer,
  cart: cartReducer,
  common: commonReducer
});
