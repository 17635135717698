import React, { Component } from "react";
import { Grid, Table } from "semantic-ui-react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import * as dfuseActions from "../dfuse/dfuseActions";

class Sales extends Component {
  render() {
    const { history, prices } = this.props;

    const displayPromotionsRows = pricesData =>
      pricesData.map(price => {
        const activeSales = price.getIn(["activeSales"]);
        if (!activeSales) {
          return null;
        } else {
          return (
            <Table.Row>
              <Table.Cell>{activeSales.get("account")}</Table.Cell>
              <Table.Cell>{activeSales.get("active")}</Table.Cell>
              <Table.Cell>{activeSales.get("min_length")}</Table.Cell>
              <Table.Cell>{activeSales.get("max_length")}</Table.Cell>
              <Table.Cell>
                {parseFloat(activeSales.get("percent")).toFixed(2)}
              </Table.Cell>
              <Table.Cell>
                {moment(activeSales.get("inserted_at") / 1000).format(
                  "MM-DD-YYYY"
                )}
              </Table.Cell>
              <Table.Cell>
                {moment(activeSales.get("updated_at") / 1000).format(
                  "MM-DD-YYYY"
                )}
              </Table.Cell>
              <Table.Cell>
                <a
                  style={{ color: "#ff4536" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://bloks.io/account/ens.xyz?loadContract=true&tab=Actions&account=ens.xyz&scope=ens.xyz&limit=100&action=updatesale&suffix=${activeSales.get(
                    "account"
                  )}&min_length=${activeSales.get(
                    "min_length"
                  )}&max_length=${activeSales.get(
                    "max_length"
                  )}&active=${activeSales.get("active")}&percent=${parseFloat(
                    activeSales.get("percent")
                  ).toFixed(2)}`}
                >
                  Edit
                </a>
                {/* eslint-disable-next-line react/self-closing-comp*/}
                <span> </span>
                <a
                  style={{ color: "#ff4536" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://bloks.io/account/ens.xyz?loadContract=true&tab=Actions&account=ens.xyz&scope=ens.xyz&limit=100&action=deletesale&suffix=${activeSales.get(
                    "account"
                  )}`}
                >
                  Delete
                </a>
              </Table.Cell>
            </Table.Row>
          );
        }
      });

    return (
      <div className="status-page">
        <h1>Promotions</h1>
        <a
          href="https://bloks.io/account/ens.xyz?loadContract=true&tab=Actions&account=ens.xyz&scope=ens.xyz&limit=100&action=newsale"
          target="_blank"
          rel="noopener noreferrer"
        >
          Create a new promotion
        </a>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Suffix</Table.HeaderCell>
              <Table.HeaderCell>Active</Table.HeaderCell>
              <Table.HeaderCell>Min Length</Table.HeaderCell>
              <Table.HeaderCell>Max Length</Table.HeaderCell>
              <Table.HeaderCell>Percent</Table.HeaderCell>
              <Table.HeaderCell>Inserted</Table.HeaderCell>
              <Table.HeaderCell>Updated</Table.HeaderCell>
              <Table.HeaderCell>Links</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{prices && displayPromotionsRows(prices)}</Table.Body>
        </Table>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      return {
        prices: state.pricing.get("prices")
      };
    },
    dispatch => {
      return {};
    }
  )(Sales)
);
