import axios from "axios";

export function clientApiLogin({ payload }) {
  return axios.post("/api/user/login_account", payload);
}

export function clientApiRegister({ payload }) {
  return axios.post("/api/user/register_account", payload);
}

export function clientApiResetPassword({ payload }) {
  return axios.post("/api/user/reset_password", payload);
}

export function clientApiUpdatePassword({ payload }) {
  return axios.post("/api/user/update_password", payload);
}

export function clientApiEditProfile({ payload }) {
  return axios.post("/api/user/edit_profile", payload);
}

export function clientApiGetProfile({ payload }) {
  return axios.post("/api/user/get_profile", payload);
}

export function getUserList({ payload }) {
  return axios.post("/api/user/get_user_list", payload);
}

export function approveUser({ payload }) {
  return axios.post("/api/user/approve_user", payload);
}

export function suspendUser({ payload }) {
  return axios.post("/api/user/suspend_user", payload);
}
