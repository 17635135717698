import { put, takeLatest } from "redux-saga/effects";
import * as WalletActions from "./walletActions";
import * as walletClientApi from "./walletClientApi";
import * as modalActions from "../modals/modalActions";
import * as commonActions from "../common/commonActions";
import * as scatterActions from "../scatter/scatterActions";
import { number_to_asset, Sym } from "eos-common";

// reset state
// when logging out on scatter module
//
// const newState = fromJS({
//   connected: false,
//   identity: null,
//   connection: null,
//   account: null,
// });

export const walletWatches = [
  initWalletWatch,
  getProvidersWatch,
  selectProviderWatch,
  scatterActionsWatch,
  logoutWatch
];

export function* initWalletWatch() {
  yield takeLatest(WalletActions.initWalletRoutine.TRIGGER, initWallet);
}

export function* getProvidersWatch() {
  yield takeLatest(WalletActions.getProvidersRoutine.TRIGGER, getProviders);
}

export function* selectProviderWatch(providerId) {
  yield takeLatest(WalletActions.selectProviderRoutine.TRIGGER, selectProvider);
}

export function* logoutWatch() {
  yield takeLatest(WalletActions.logoutRoutine.TRIGGER, logout);
}

export function* scatterActionsWatch() {
  yield takeLatest(commonActions.setPageTitleRoutine.TRIGGER, derp);
}

export function* logout() {
  try {
    yield walletClientApi.logout();
    yield put(WalletActions.logoutRoutine.success());
  } catch (e) {
    yield put(WalletActions.logoutRoutine.failure(e.message));
  }
}

function* derp() {
  console.log("derp was called!");
}

export function* buyAccount({ payload }) {
  const { buyAcctName, amount, key } = payload.values;

  console.log({ buyAcctName, amount, key }, "came through");

  try {
    const response = yield walletClientApi.createTransferToBuyMarketplaceAcct({
      amount,
      buyAcctName,
      key
    });

    console.log("response is...", response);
    yield put(scatterActions.buyAccountRoutine.success(response));
  } catch (e) {
    yield put(scatterActions.buyAccountRoutine.failure(e.message));
  }
}

export function* placeBid({ payload }) {
  const { account4auction, amount, key } = payload.values;

  const assetString = number_to_asset(
    Number(amount),
    new Sym("EOS", 4)
  ).to_string();
  try {
    const response = yield walletClientApi.createTransferToPlaceBid({
      account4auction,
      amount: assetString,
      owner_key_str: key,
      active_key_str: key
    });
    yield put(scatterActions.bidAuctionRoutine.success(response));
  } catch (err) {
    yield put(scatterActions.bidAuctionRoutine.failure(err));
  }
}

export function* startAuction({ payload }) {
  const {
    auctionAcctName,
    paymentAcct,
    buyNowPrice,
    reservePrice
  } = payload.values;
  const buyNowPriceWithSymbol = buyNowPrice.includes(" EOS")
    ? buyNowPrice
    : `${buyNowPrice} EOS`;
  const reservePriceWithSymbol = reservePrice.includes(" EOS")
    ? reservePrice
    : `${reservePrice} EOS`;
  try {
    const response = yield walletClientApi.startAuction({
      acctName: auctionAcctName,
      buyprice: buyNowPriceWithSymbol,
      reserve: reservePriceWithSymbol,
      payAcct: paymentAcct
    });
    yield put(scatterActions.startAuctionRoutine.success(response));
  } catch (e) {
    scatterActions.startAuctionRoutine.failure(e);
  }
}

export function* sellAccount({ payload }) {
  const { sellAcctName, paymentAcct, sellAmount } = payload.values;
  const sellAmountWithSymbol = sellAmount.includes(" EOS")
    ? sellAmount
    : `${sellAmount} EOS`;
  try {
    const response = yield walletClientApi.sellAccount({
      acctName: sellAcctName,
      amount: sellAmountWithSymbol,
      payAcct: paymentAcct,
      msg: ""
    });

    yield put(scatterActions.sellAccountRoutine.success(response));
  } catch (e) {
    yield put(scatterActions.sellAccountRoutine.failure(e));
  }
}

export function* cancelAuction({ payload }) {
  const { account4auction, key } = payload.values;
  try {
    const response = yield walletClientApi.cancelAuction({
      account4auction,
      owner_key_str: key,
      active_key_str: key
    });
    yield put(scatterActions.cancelAuctionRoutine.success(response));
  } catch (err) {
    console.error(err);
    yield put(scatterActions.cancelAuctionRoutine.failure(err));
  }
}

export function* bidnameBid({ payload }) {
  const { bidAcctName, amount } = payload.values;
  try {
    const response = yield walletClientApi.createTransferToBidBidname({
      to: "bidname.com",
      amount,
      bidAcctName
    });
    yield put(scatterActions.bidnameBidRoutine.success(response));
  } catch (err) {
    yield put(scatterActions.bidnameBidRoutine.failure(err));
  }
}

export function* buyAuctionAccount({ payload }) {
  const { account4auction, amount, key } = payload.values;
  const amountWithSymbol = amount.includes(" EOS") ? amount : `${amount} EOS`;
  try {
    const response = yield walletClientApi.createTransferToBuyAuctionAccount({
      account4auction,
      amount: amountWithSymbol,
      owner_key_str: key,
      active_key_str: key
    });
    yield put(scatterActions.buyAuctionAccountRoutine.success(response));
  } catch (err) {
    yield put(scatterActions.buyAuctionAccountRoutine.failure(err));
  }
}

export function* updateSale({ payload }) {
  const { sellAcctName, sellAmount } = payload.values;
  const sellAmountWithSymbol = sellAmount.includes(" EOS")
    ? sellAmount
    : `${sellAmount} EOS`;
  try {
    const response = yield walletClientApi.updateSale({
      sellAcctName,
      sellAmount: sellAmountWithSymbol,
      msg: ""
    });
    yield put(scatterActions.updateSaleRoutine.success(response));
  } catch (err) {
    yield put(scatterActions.updateSaleRoutine.failure(err));
  }
}

export function* cancelSale({ payload }) {
  const { sellAcctName, key } = payload.values;
  try {
    const response = yield walletClientApi.cancelSale({
      account4sale: sellAcctName,
      owner_key_str: key,
      active_key_str: key
    });

    yield put(scatterActions.cancelSaleRoutine.success(response));
  } catch (err) {
    yield put(scatterActions.cancelSaleRoutine.failure(err));
  }
}

export function* createAccountsWithEos({ payload }) {
  try {
    const response = yield walletClientApi.createPremiumAccounts(payload);
    yield put(scatterActions.buyAccountRoutine.success(response));
    yield put(modalActions.openEosSuccessModalRoutine.trigger());
  } catch (e) {
    yield put(scatterActions.buyAccountRoutine.failure(e.message));
    yield put(modalActions.openEosFailureModalRoutine.trigger());
  }
}

function* initWallet() {
  return true;
}

function* getProviders() {
  const providers = yield walletClientApi.getProviders();

  yield put(WalletActions.getProvidersRoutine.success(providers));
}

function* selectProvider(id) {
  const accountInfo = yield walletClientApi.selectProvider(id);
  yield put(WalletActions.selectProviderRoutine.success(accountInfo));
  yield put(modalActions.closeLoginModalRoutine());
}
