import ScatterJS from "@scatterjs/core";
import ScatterEOS from "@scatterjs/eosjs2";
import { JsonRpc, Api } from "eosjs";
import axios from "axios";

ScatterJS.plugins(new ScatterEOS());
const network = ScatterJS.Network.fromJson({
  blockchain: process.env.REACT_APP_SCATTER_BLOCKCHAIN,
  protocol: process.env.REACT_APP_SCATTER_PROTOCOL,
  host: process.env.REACT_APP_SCATTER_HOST,
  port: process.env.REACT_APP_SCATTER_PORT,
  chainId: process.env.REACT_APP_SCATTER_CHAIN_ID
});

const apiSigner = {
  getAvailableKeys: async () => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_SIGNER}/api/scatter/keys`
      );
      return resp.data;
    } catch (e) {
      console.error("Error in getAvailableKeys");
      throw e;
    }
  },
  sign: async signargs => {
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_API_SIGNER}/api/scatter/sign`,
        {
          signargs
        }
      );
      return resp.data;
    } catch (e) {
      console.error("Error in signing");
      throw e;
    }
  }
};
const requiredFields = { accounts: [network] };

const rpc = new JsonRpc(process.env.REACT_APP_EOS_ENDPOINT); //network.fullhost());
let eos;
let api;
let apiUnpaid;

export function getScatter() {
  return ScatterJS.scatter;
}

export function* getScatterIdentity() {
  const identity = yield ScatterJS.scatter.getIdentity(requiredFields);
  return identity;
}

export function* connectToScatter() {
  const connected = yield ScatterJS.connect(
    process.env.REACT_APP_SCATTER_APP_NAME,
    { network }
  );
  eos = ScatterJS.eos(network, Api, { rpc });

  const signatureProvider = ScatterJS.eosMultiHook(network, [apiSigner]);
  api = new Api({ rpc, signatureProvider });
  apiUnpaid = new Api({ rpc, signatureProvider: ScatterJS.eosHook(network) });

  return connected;
}

export function* linkScatter() {
  const identity = yield getScatterIdentity();
  try {
    const linked = yield ScatterJS.scatter.identity.accounts.find(
      x => x.blockchain === "eos"
    );
    return linked;
  } catch (e) {
    console.error("Error linking scatter", e);
    throw e;
  }
  //return identity;
}

export function* loginScatter() {
  const result = yield ScatterJS.login();
  return result;
}

export function* forgetIdentity() {
  const forgotten = yield ScatterJS.scatter.forgetIdentity();
  return forgotten;
}

export function* createTransferToBuyMarketplaceAcct({
  to,
  amount,
  buyAcctName
}) {
  try {
    const account = yield ScatterJS.scatter.identity.accounts.find(
      x => x.blockchain === "eos"
    );
    const resp = yield api.transact(
      {
        actions: [
          {
            account: "eosio.token",
            name: "transfer",
            authorization: [
              {
                actor: "enscpu.xyz",
                permission: "payforcpu"
              },
              {
                actor: account.name,
                permission: account.authority
              }
            ],
            data: {
              from: account.name,
              to,
              quantity: amount,
              memo: `buy-${buyAcctName}-${account.publicKey}-${account.publicKey}-`
            }
          }
        ]
      },
      {
        broadcast: false,
        sign: true,
        blocksBehind: 3,
        expireSeconds: 30
      }
    );
    const eosResp = yield rpc.push_transaction(resp);
    return eosResp;
  } catch (e) {
    console.error("Error in create transfer scatter api", e);
    throw e;
  }
}

export function* createTransferToBidBidname({ to, amount, bidAcctName }) {
  try {
    const account = yield ScatterJS.scatter.identity.accounts.find(
      x => x.blockchain === "eos"
    );
    const resp = yield api.transact(
      {
        actions: [
          {
            account: "eosio.token",
            name: "transfer",
            authorization: [
              {
                actor: "enscpu.xyz",
                permission: "payforcpu"
              },
              {
                actor: account.name,
                permission: account.authority
              }
            ],
            data: {
              from: account.name,
              to,
              quantity: amount,
              memo: `${bidAcctName},ravensharing`
            }
          }
        ]
      },
      {
        broadcast: false,
        sign: true,
        blocksBehind: 3,
        expireSeconds: 30
      }
    );
    const eosResp = yield rpc.push_transaction(resp);
    return eosResp;
  } catch (e) {
    console.error("Error in create transfer scatter api", e);
    throw e;
  }
}

export function* createPremiumAccounts({
  cartItems,
  totalEosAmount,
  referrer
}) {
  const actions = [];
  const account = yield ScatterJS.scatter.identity.accounts.find(
    x => x.blockchain === "eos"
  );
  let discount = "";

  const createTransferAction = item => {
    const name = item.get("account_name");
    const amount = item.get("eosPrice");
    const newAccount =
      name.length === 12 && name.includes(".") === false ? "0" : "1";
    const to =
      newAccount !== "1" ? "ens.xyz" : item.getIn(["suffixInfo", "pay_to"]);
    const memoPattern = item.getIn(
      ["suffixInfo", "memo_pattern"],
      "**newAccount**-**eosName**-**eosPublicKey**-**eosPublicKey**-**refAccount**-**isPaypal**-**discount**"
    );
    const memo = memoPattern
      .replace("**newAccount**", newAccount)
      .replace("**eosName**", name)
      .replace(/\*\*eosPublicKey\*\*/g, account.publicKey)
      .replace("**refAccount**", referrer !== "none" ? `${referrer}` : "")
      .replace("**isPaypal**", "0")
      .replace("**discount**", discount);

    const action = {
      account: "eosio.token",
      name: "transfer",
      authorization: [
        {
          actor: "enscpu.xyz",
          permission: "payforcpu"
        },
        {
          actor: account.name,
          permission: account.authority
        }
      ],
      data: {
        from: account.name,
        to,
        quantity: `${amount} EOS`,
        memo
      }
    };
    discount = "multicart";
    return action;
  };

  try {
    try {
      cartItems.map(item => actions.push(createTransferAction(item)));
      const resp = yield api.transact(
        {
          actions
        },
        {
          broadcast: false,
          sign: true,
          blocksBehind: 3,
          expireSeconds: 30
        }
      );
      const eosResp = yield rpc.push_transaction(resp);
      return eosResp;
    } catch (e) {
      console.error("Error in create transfer scatter api", e);
      throw e;
    }
  } catch (e) {
    console.error("Error in create premium account", e);
    throw e;
  }
}

/*
export function* createPremiumAccount({cartItems, amount, referrer}) {
  try {
    const to = 'ens.xyz';
    const newAccount = name.length===12 && name.includes('.')===false?'newregularaccount-':'';
    const account = yield ScatterJS.scatter.identity.accounts.find(x => x.blockchain === 'eos');
    const memo = `${newAccount}${name}-${account.publicKey}-${account.publicKey}${referrer!=='none'?`-${referrer}`:''}`;
    const resp = yield createTransfer({to, amount: `${amount} EOS`, memo});
    return resp;
  } catch (e) {
    console.error('Error in create premium account', e);
    throw e;
  }
}
*/

export function* broadcastTransaction({ contract, method, data }) {
  yield connectToScatter();
  yield linkScatter();
  try {
    const account = yield ScatterJS.scatter.identity.accounts.find(
      x => x.blockchain === "eos"
    );
    const res = yield api.transact({
      actions: [
        {
          account: contract,
          name: method,
          authorization: [
            {
              actor: account.name,
              permission: account.authority
            }
          ],
          data
        }
      ]
    });
    return res;
  } catch (e) {
    console.error("Error in broadcast transaction - scatter api", e);
    return e;
  }
}

export function* sellAccount({ acctName, amount, payAcct, msg }) {
  try {
    const account = yield ScatterJS.scatter.identity.accounts.find(
      x => x.blockchain === "eos"
    );
    const res = yield apiUnpaid.transact(
      {
        actions: [
          {
            account: "eosio",
            name: "updateauth",
            authorization: [{ actor: acctName, permission: "owner" }],
            data: {
              account: acctName,
              permission: "owner",
              parent: "",
              auth: {
                threshold: 1,
                keys: [],
                accounts: [
                  {
                    permission: {
                      actor: "market.xyz",
                      permission: "eosio.code"
                    },
                    weight: 1
                  }
                ],
                waits: []
              }
            }
          },
          {
            account: "market.xyz",
            name: "sell",
            authorization: [{ actor: acctName, permission: "owner" }],
            data: {
              account4sale: acctName,
              saleprice: amount,
              paymentaccnt: payAcct,
              message: msg
            }
          }
        ]
      },
      {
        blocksBehind: 3,
        expireSeconds: 30
      }
    );
    return res;
  } catch (err) {
    return { error: { details: [err] } };
  }
}

export function* startAuction({ acctName, buyprice, reserve, payAcct }) {
  try {
    const account = yield ScatterJS.scatter.identity.accounts.find(
      x => x.blockchain === "eos"
    );
    const res = yield apiUnpaid.transact(
      {
        actions: [
          {
            account: "eosio",
            name: "updateauth",
            authorization: [{ actor: acctName, permission: "owner" }],
            data: {
              account: acctName,
              permission: "owner",
              parent: "",
              auth: {
                threshold: 1,
                keys: [],
                accounts: [
                  {
                    permission: {
                      actor: "market.xyz",
                      permission: "eosio.code"
                    },
                    weight: 1
                  }
                ],
                waits: []
              }
            }
          },
          {
            account: "market.xyz",
            name: "startauction",
            authorization: [{ actor: acctName, permission: "owner" }],
            data: {
              account4auction: acctName,
              buyitnowprice: buyprice,
              reserve,
              paymentaccnt: payAcct
            }
          }
        ]
      },
      {
        blocksBehind: 3,
        expireSeconds: 30
      }
    );
    return res;
  } catch (err) {
    return { error: { details: [err] } };
  }
}

export function* updateSale({ sellAcctName, sellAmount, msg }) {
  const account = yield ScatterJS.scatter.identity.accounts.find(
    x => x.blockchain === "eos"
  );
  try {
    const res = yield apiUnpaid.transact(
      {
        actions: [
          {
            account: "market.xyz",
            name: "update",
            authorization: [
              {
                actor: account.name,
                permission: account.authority
              }
            ],
            data: {
              account4sale: sellAcctName,
              saleprice: sellAmount,
              message: msg
            }
          }
        ]
      },
      {
        blocksBehind: 3,
        expireSeconds: 30
      }
    );
    return res;
  } catch (err) {
    return err;
  }
}

export function* cancelSale({ account4sale, owner_key_str, active_key_str }) {
  const account = yield ScatterJS.scatter.identity.accounts.find(
    x => x.blockchain === "eos"
  );
  try {
    const res = yield apiUnpaid.transact(
      {
        actions: [
          {
            account: "market.xyz",
            name: "cancel",
            authorization: [
              {
                actor: account.name,
                permission: account.authority
              }
            ],
            data: {
              account4sale,
              owner_key_str,
              active_key_str
            }
          }
        ]
      },
      {
        blocksBehind: 3,
        expireSeconds: 30
      }
    );
    return res;
  } catch (err) {
    return err;
  }
}

export function* cancelAuction({
  account4auction,
  owner_key_str,
  active_key_str
}) {
  const account = yield ScatterJS.scatter.identity.accounts.find(
    x => x.blockchain === "eos"
  );
  try {
    const res = yield apiUnpaid.transact(
      {
        actions: [
          {
            account: "market.xyz",
            name: "cancelauct",
            authorization: [
              {
                actor: account.name,
                permission: account.authority
              }
            ],
            data: {
              account4auction,
              owner_key_str,
              active_key_str
            }
          }
        ]
      },
      {
        blocksBehind: 3,
        expireSeconds: 30
      }
    );
    return res;
  } catch (err) {
    return err;
  }
}

export function* createTransferToPlaceBid({ amount, account4auction }) {
  try {
    const account = yield ScatterJS.scatter.identity.accounts.find(
      x => x.blockchain === "eos"
    );
    const ownerKey = "";
    const activeKey = "";
    const referrer = "";
    const resp = yield api.transact(
      {
        actions: [
          {
            account: "eosio.token",
            name: "transfer",
            authorization: [
              {
                actor: "enscpu.xyz",
                permission: "payforcpu"
              },
              {
                actor: account.name,
                permission: account.authority
              }
            ],
            data: {
              from: account.name,
              to: "market.xyz",
              quantity: amount,
              memo: `bid-${account4auction}-${ownerKey}-${activeKey}-${referrer}`
            }
          }
        ]
      },
      {
        broadcast: false,
        sign: true,
        blocksBehind: 3,
        expireSeconds: 30
      }
    );
    const eosResp = yield rpc.push_transaction(resp);
    return eosResp;
  } catch (e) {
    console.error("Error in create transfer scatter api", e);
    throw e;
  }
}

export function* createTransferToBuyAuctionAccount({
  amount,
  account4auction,
  owner_key_str,
  active_key_str,
  referrer
}) {
  try {
    const account = yield ScatterJS.scatter.identity.accounts.find(
      x => x.blockchain === "eos"
    );
    const ownerKey = owner_key_str;
    const activeKey = active_key_str;
    const resp = yield api.transact(
      {
        actions: [
          {
            account: "eosio.token",
            name: "transfer",
            authorization: [
              {
                actor: "enscpu.xyz",
                permission: "payforcpu"
              },
              {
                actor: account.name,
                permission: account.authority
              }
            ],
            data: {
              from: account.name,
              to: "market.xyz",
              quantity: amount,
              memo: `buynow-${account4auction}-${ownerKey}-${activeKey}-${referrer}`
            }
          }
        ]
      },
      {
        broadcast: false,
        sign: true,
        blocksBehind: 3,
        expireSeconds: 30
      }
    );
    const eosResp = yield rpc.push_transaction(resp);
    return eosResp;
  } catch (e) {
    console.error("Error in create transfer scatter api", e);
    throw e;
  }
}

export function* getTableRows(query) {
  try {
    const resp = yield rpc.get_table_rows(query);
    return resp;
  } catch (e) {
    console.error(e.message);
    return e;
  }
}
