import axios from "axios";

export function fetchPrices({ widget }) {
  return axios.get(`/api/ens/prices/${widget}`);
}

export function fetchRate() {
  return axios.get("/api/ens/exchange/EOS/USD");
}

export function searchName(name) {
  return axios.post(
    "https://eos.greymass.com/v1/chain/get_account",
    { account_name: name },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }
  );
}

export async function searchBidname(name) {
  const resp = await axios.get(`/api/ens/starteospassthrough/${name}`);
  return resp.data;
}
