/* eslint-disable react/button-has-type */
import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { ReactComponent as EosLogo } from "../../assets/images/eos.svg";
import * as cartActions from "../../cart/cartActions";
import * as modalActions from "../../modals/modalActions";
import * as pricingClientApi from "../../pricing/pricingClientApi";

class PremiumNameCard extends Component {
  state = {
    available: "loading"
  };

  componentDidMount() {
    const { item } = this.props;
    const { account_name } = item;
    pricingClientApi
      .searchName(account_name)
      .then(() => this.setState({ available: false }))
      .catch(() => this.setState({ available: true }));
  }

  render() {
    const { price, addNameToCart, openCartModal, item, t } = this.props;
    const { available } = this.state;
    if (available === "loading") {
      return (
        <button
          sortorder={
            price && price.suffixInfo ? price.suffixInfo.display_order : 10000
          }
          className="eos-account"
          key={item.account_name}
        >
          <span className="account-name">{item.account_name}</span>
          <span className="account-info available">
            <span className="info">
              <span>Checking Availability...</span>
            </span>
          </span>
        </button>
      );
    }
    return (
      // eslint-disable-next-line react/button-has-type
      <button
        sortorder={
          price && price.suffixInfo ? price.suffixInfo.display_order : 10000
        }
        className={`eos-account ${available ? "available" : ""} ${
          price ? (price.onSale ? "on-sale" : "") : "noprice"
        } ${item.bidname === true ? "auction" : ""}`}
        disabled={!available}
        key={item.account_name}
        onClick={() => {
          if (!available || !price) return;
          //this.showPurchase(item.account_name, price.eos, price.usd);//.toFixed(2));
          addNameToCart({
            ...item,
            eosPrice: price.eos,
            usdPrice: price.usd,
            regEosPrice: price.regEosPrice,
            regUsdPrice: price.regUsdPrice,
            suffixInfo: price.suffixInfo
          });
          //showPurchaseModal();
          openCartModal();
        }}
      >
        <span className="account-name">
          {item.account_name}{" "}
          {price && price.onSale && (
            <span className="hasDiscount">
              {Math.round(price.salesDiscount * 100)}% Off!
            </span>
          )}
        </span>
        {available && price && (
          <span className="account-info available">
            <span className="info">
              <EosLogo />
              <span>{price.eos}</span>
            </span>
            <span className="info">
              <Icon name="dollar sign" />
              <span>{price.usd}</span>
            </span>
          </span>
        )}
        {available && !price && !item.bidname && (
          <span className="account-info">
            <span>{t("home.unavailable")}</span>
          </span>
        )}
        {!available && (
          <span className="account-info">
            <span>{t("home.unavailable")}</span>
          </span>
        )}
      </button>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      return {};
    },
    dispatch => {
      return {
        addNameToCart: args => {
          dispatch(cartActions.addNameToCartRoutine(args));
        },
        openCartModal: args => {
          dispatch(modalActions.openCartModalRoutine(args));
        }
      };
    }
  )(withTranslation()(PremiumNameCard))
);
