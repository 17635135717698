import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Form } from "semantic-ui-react";
import { loginFormHandler } from "../userActions";

class LoginForm extends Component {
  render() {
    const { handleSubmit, loggingIn } = this.props; // handleSubmit is provided by reduxForm

    return (
      <Form onSubmit={handleSubmit(loginFormHandler)}>
        <div className="login-form-field">
          <label htmlFor="username">Username</label>
          <Field
            component="input"
            type="text"
            name="username"
            placeholder="Username"
            autoComplete="off"
          />
        </div>
        <div className="login-form-field">
          <label htmlFor="password">Password</label>
          <Field
            className="modalInputDiv"
            component="input"
            type="password"
            name="password"
            autoComplete="off"
          />
        </div>
        <Button
          fluid
          className="login-button"
          type="submit"
          disabled={loggingIn}
        >
          Login
        </Button>
      </Form>
    );
  }
}

export default reduxForm({
  form: "LoginForm" // a unique identifier for this form
})(LoginForm);
