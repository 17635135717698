import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import { withTranslation } from "react-i18next";
import * as cartActions from "../../cart/cartActions";
import * as modalActions from "../../modals/modalActions";
import { ReactComponent as EosLogo } from "../../assets/images/eos.svg";

class MarketplaceAuctionCard extends Component {
  render() {
    const { widget, referrer, item } = this.props;
    return (
      <Link
        as="button"
        to={`/marketplace?${widget === "true" ? "widget=true&" : ""}name=${
          item.account_name
        }${referrer ? `&ref=${referrer}` : ""}`}
        className="eos-account available auction-link"
        key={item.account_name}
      >
        <span className="account-name">{item.account_name}</span>
        <span className="account-info available">
          <span className="info">
            <EosLogo />
            <span>{item.eosBuyItNowPrice.split(" ")[0]} - Buy Now</span>
          </span>
          <span className="info">
            <EosLogo />
            <span>{item.eosHighBid.split(" ")[0]} - High Bid</span>
          </span>
          <span>Available from Marketplace</span>
        </span>
      </Link>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const q = queryString.parse(ownProps.location.search);
      const { ref, widget } = q;
      return {
        widget,
        referrer: ref ? ref : "none"
      };
    },
    dispatch => {
      return {
        addNameToCart: args => {
          dispatch(cartActions.addNameToCartRoutine(args));
        },
        openCartModal: args => {
          dispatch(modalActions.openCartModalRoutine(args));
        }
      };
    }
  )(withTranslation()(MarketplaceAuctionCard))
);
