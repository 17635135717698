import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

class Status extends Component {
  render() {
    const { history } = this.props;

    return <div className="status-page">Status Page</div>;
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const { role } = ownProps.match.params;
      return {
        role
      };
    },
    dispatch => {
      return {};
    }
  )(Status)
);
