import React, { Component } from "react";
import { Modal, Button, Header } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import * as modalActions from "../modalActions";
import * as scatterActions from "../../scatter/scatterActions";
import AuctionAccountForm from "../../marketplace/AuctionAccountForm";

class AuctionAccountModal extends Component {
  render() {
    const {
      scatterError,
      scatterSuccess,
      t,
      showAuctionAccountModal,
      closeAuctionModal
    } = this.props;

    return (
      <Modal
        open={showAuctionAccountModal}
        onClose={closeAuctionModal}
        size="small"
      >
        <Header content={t("marketplace.auction.modal.header")} />
        <Modal.Content>
          <Trans i18nKey="marketplace.auction.modal.content">
            Placeholder{" "}
            <a href="https://eosnameservice.io/terms-conditions">
              Terms and Conditions
            </a>
            . More placeholder{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.youtube.com/embed/P2YtAdqZTF8?autoplay=1"
            >
              Click here for instructions
            </a>
            .<br />
            <br />
            Language file missing.
          </Trans>
          <p />
          <p className="owner-account-warning">
            <Trans i18nKey="marketplace.auction.owneraccountreminder">
              Language File Missing.
              <br />
              <br />
              Language file missing
            </Trans>
          </p>
          <AuctionAccountForm />
          <p />
          {scatterSuccess && (
            <p>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://bloks.io/transaction/${scatterSuccess}`}
              >
                See transaction on blocks.io
              </a>
            </p>
          )}
          {scatterError && <p>{JSON.stringify(scatterError)}</p>}
        </Modal.Content>
      </Modal>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const showAuctionAccountModal = state.modal.get(
        "showAuctionAccountModal"
      );
      return {
        showAuctionAccountModal
      };
    },
    dispatch => {
      return {
        closeAuctionModal: args => {
          dispatch(modalActions.closeAuctionAccountModalRoutine());
        }
      };
    }
  )(withTranslation()(AuctionAccountModal))
);
