import { fromJS } from "immutable";
import * as walletActions from "./walletActions";

const defaultState = fromJS({
  connection: null,
  providers: [],
  isAuthenticated: false,
  activeKey: "",
  loading: false,
  txBusy: false,
  txSuccess: "",
  txError: ""
});

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case walletActions.getProvidersRoutine.SUCCESS: {
      return state.set("providers", action.payload);
    }
    case walletActions.selectProviderRoutine.SUCCESS: {
      const { accountName, activeKey } = action.payload;
      return state
        .set("isAuthenticated", accountName)
        .set("activeKey", activeKey);
    }
    case walletActions.logoutRoutine.SUCCESS: {
      return state.set("isAuthenticated", false).set("activeKey", "");
    }
    default:
      return state;
  }
}
