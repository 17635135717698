import React, { Component } from "react";
import { Modal, Divider, Button, Input } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import * as modalActions from "../modalActions";
import * as walletActions from "../../wallet/walletActions";
import { Provider } from "./Provider";

class LoginModal extends Component {
  render() {
    const { showLoginModal, closeLoginModal, t, providers } = this.props;

    return (
      <Modal size="tiny" open={showLoginModal} onClose={closeLoginModal}>
        <Modal.Header>{t("modals.login.header")}</Modal.Header>
        <Modal.Content>
          <Divider hidden />
          {providers.map(provider => (
            <Provider
              label={provider.meta.shortName}
              id={provider.id}
              key={provider.id}
              onClick={this.props.selectProvider}
            />
          ))}
        </Modal.Content>
      </Modal>
    );
  }
}

export default withRouter(
  connect(
    state => {
      const showLoginModal = state.modal.get("showLoginModal");
      const providers = state.wallet.get("providers");
      return {
        showLoginModal,
        providers
      };
    },
    dispatch => {
      return {
        selectProvider: providerId => {
          dispatch(walletActions.selectProviderRoutine(providerId));
        },
        closeLoginModal: args => {
          dispatch(modalActions.closeLoginModalRoutine());
        }
      };
    }
  )(withTranslation()(LoginModal))
);
