import { put, takeLatest } from "redux-saga/effects";
import * as pricingActions from "./pricingActions";
import * as pricingClientApi from "./pricingClientApi";

export const pricingWatches = [fetchPricesWatch];

export function* fetchPricesWatch() {
  yield takeLatest(pricingActions.fetchPricesRoutine.TRIGGER, fetchPrices);
}

function* fetchPrices({ payload }) {
  try {
    const { data: prices } = yield pricingClientApi.fetchPrices(payload);
    const { data: rate } = yield pricingClientApi.fetchRate();
    yield put(pricingActions.fetchPricesRoutine.success({ prices, rate }));
  } catch (error) {
    yield put(pricingActions.fetchPricesRoutine.failure());
  }
}
