/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable consistent-return */
/* eslint-disable react/button-has-type */
import React, { Component } from "react";
import { Modal, Dimmer, Loader, Segment } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class CreatingAccountModal extends Component {
  render() {
    const { showCreatingAccountModal } = this.props;

    return (
      <Modal size="tiny" open={showCreatingAccountModal}>
        <Modal.Header>Account creation in progress</Modal.Header>

        <Modal.Content>
          <Dimmer active>
            <Loader content="" />
          </Dimmer>
          Your account is being created. Do not close or refresh this page until
          the transaction is complete.
        </Modal.Content>
      </Modal>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const showCreatingAccountModal = state.modal.get(
        "showCreatingAccountModal"
      );
      return {
        showCreatingAccountModal
      };
    },
    dispatch => {
      return {};
    }
  )(withTranslation()(CreatingAccountModal))
);
