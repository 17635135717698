/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Segment,
  Divider,
  Button,
  Modal,
  Message,
  Header
} from "semantic-ui-react";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import PasswordResetForm from "./PasswordResetForm";

class LoginPage extends Component {
  render() {
    const {
      loggedIn,
      loggingIn,
      loginError,
      registerError,
      user,
      registerStep2,
      passwordResetMessage
    } = this.props;

    return (
      <div className="signInOrUp">
        <Segment padded>
          <Modal trigger={<Button color="black">Sign In</Button>}>
            <Modal.Header>Sign In</Modal.Header>
            <Modal.Content image>
              <LoginForm
                loggingIn={loggingIn}
                initialValues={{ history: this.props.history }}
              />
            </Modal.Content>
            <Message
              attached="bottom"
              hidden={loginError === undefined}
              warning
            >
              {loginError}
            </Message>
          </Modal>

          <Divider horizontal>Or</Divider>
          <Modal trigger={<Button color="green">Sign Up</Button>}>
            <Modal.Header>Sign Up</Modal.Header>
            <Modal.Content image>
              <Segment hidden={registerStep2 == true}>
                <RegisterForm initialValues={{ history: this.props.history }} />
              </Segment>
              <Header
                hidden={registerStep2 == undefined}
                content="Please verify your email to complete the Signup process."
              />
            </Modal.Content>
            <Message
              attached="bottom"
              hidden={registerError === undefined}
              warning
            >
              {registerError}
            </Message>
          </Modal>
          <Divider horizontal />

          <Modal
            trigger={<a className="forgotPasswordLink">Forgot password?</a>}
          >
            <Modal.Header>Password Reset</Modal.Header>
            <Modal.Content>
              <PasswordResetForm
                initialValues={{ history: this.props.history }}
              />
            </Modal.Content>
            <Message
              attached="bottom"
              hidden={passwordResetMessage === undefined}
            >
              {passwordResetMessage}
            </Message>
          </Modal>
        </Segment>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => {
      return {
        loggedIn: state.user.get("loggedIn"),
        loggingIn: state.user.get("loggingIn"),
        loginError: JSON.stringify(state.user.get("loginError")),
        registerError: state.user.get("registerError"),
        user: state.user.get("user"),
        registerStep2: state.user.get("registerStep2"),
        passwordResetMessage: state.user.get("passwordResetMessage")
      };
    },
    dispatch => {
      return {};
    }
  )(LoginPage)
);
